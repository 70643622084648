@import 'variables';
@import 'functions';

// Media query blocks
// 1201px +
@mixin desktop {
  @media (min-width: #{$screen-width-sm-desktop + 1}) {
    @content;
  }
}

// upto 1200px
@mixin sm-desktop {
  @media (max-width: #{$screen-width-sm-desktop}) {
    @content;
  }
}

// upto 1000px
@mixin tablet {
  @media (max-width: #{$screen-width-tablet}) {
    @content;
  }
}

// upto 790px
@mixin sm-tablet {
  @media (max-width: #{$screen-width-sm-tablet}) {
    @content;
  }
}

// upto 560px
@mixin mobile {
  @media (max-width: #{$screen-width-mobile}) {
    @content;
  }
}

// upto 350px
@mixin sm-mobile {
  @media (max-width: #{$screen-width-sm-mobile}) {
    @content;
  }
}


// from 1201px
@mixin desktop-min {
  @media (min-width: #{$screen-width-sm-desktop + 1}) {
    @content;
  }
}

// from 1001px
@mixin sm-desktop-min {
  @media (min-width: #{$screen-width-tablet + 1}) {
    @content;
  }
}

// from 791px
@mixin tablet-min {
  @media (min-width: #{$screen-width-sm-tablet + 1}) {
    @content;
  }
}

// from 561px
@mixin sm-tablet-min {
  @media (min-width: #{$screen-width-mobile + 1}) {
    @content;
  }
}

// from 351px
@mixin mobile-min {
  @media (min-width: #{$screen-width-sm-mobile + 1}) {
    @content;
  }
}

// from 1001px to 1200px
@mixin sm-desktop-only {
  @media (min-width: #{$screen-width-tablet + 1}) and (max-width: #{$screen-width-sm-desktop}) {
    @content;
  }
}

// from 791px to 1000px
@mixin tablet-only {
  @media (min-width: #{$screen-width-sm-tablet + 1}) and (max-width: #{$screen-width-tablet}) {
    @content;
  }
}

// from 561px to 790px
@mixin sm-tablet-only {
  @media (min-width: #{$screen-width-mobile + 1}) and (max-width: #{$screen-width-sm-tablet}) {
    @content;
  }
}

// from 351px to 560px
@mixin mobile-only {
  @media (min-width: #{$screen-width-sm-mobile + 1}) and (max-width: #{$screen-width-mobile}) {
    @content;
  }
}

// Mixin helpers
// Generate border and text color mixin
// @param {color} $color - hex|rgb color

@mixin border-text-color ($color) {
  border-color: $color !important;
  color: $color !important;
}

// Generate simple text colour mixin
// @param {color} $color - hex|rgb color
@mixin simple-text-color ($color) {
  color: $color;
}

// Generate column mixin
// @param {int} $col - number of columns to span
// @param {int} $sum - total number of columns

@mixin col ($col, $sum) {
  $percentage: percentage($col/$sum);
  float: left;
  width: $percentage;

  &_push {
    margin-left: $percentage;
  }

  &_push_r {
    margin-right: $percentage;
  }

  &_no_push {
    margin-left: 0;
  }

  &_no_push_r {
    margin-right: 0;
  }
}

// Prepends the child class to the parent class to change selector order
// @param {class} $child - class, id or selector

@mixin prepend-parent($child) {
  @at-root #{selector-unify(&, $child)} {
    @content;
  }
}

// Generate width mixin
// @param {int|auto|fit-content|inherit|max-content|min-content|unset} $width - total width
// @param {string} $unit - unit type e.g. px, %, rem etc
// @param {int|auto|fit-content|inherit|max-content|min-content|unset} optional $min - min width override
// @param {int|auto|fit-content|inherit|max-content|min-content|unset} optional $max - max width override

@mixin width ($width, $min: null, $max: null) {
  width: $width;

  @if (is-not-null($min)) {
    min-width: $min;
  }

  @if (is-not-null($max)) {
    max-width: $max;
  }
}

// Generate background position mixin
// @param {int} optional {unit} $xpos - x position value
// @param {int} optional {unit} $ypos - y position value
// @param {int} optional {unit} optional $disabled_x_offset - x position value for disabled - default NULL
// @param {int} optional {unit} optional $disabled_y_offset - y position value for disabled - default $ypos if $disabled_x_offset !== NULL
@mixin bg-pos($xpos, $ypos, $disabled_x_offset: null, $disabled_y_offset: $ypos) {
  background-position: $xpos $ypos;

  @if ($disabled_x_offset) {
    &.disabled {
      background-position: $disabled_x_offset $disabled_y_offset;
    }
  }
}

// Generate height mixin
// @param {int|auto|fit-content|inherit|max-content|min-content|unset} $height - total height
// @param {string} $unit - unit type e.g. px, %, rem etc
// @param {int|auto|fit-content|inherit|max-content|min-content|unset} optional $min - min height override
// @param {int|auto|fit-content|inherit|max-content|min-content|unset} optional $max - max height override

@mixin height ($height, $min: null, $max: null) {
  height: $height;

  @if (is-not-null($min)) {
    min-height: $min;
  }

  @if (is-not-null($max)) {
    max-height: $max;
  }
}

// Add seasure colour
@mixin seasure () {

  &.seasure,
  &.seasure_only {
    color: $vv-seasure-blue;
  }

  &.seasure:after,
  &.seasure_bg {
    background-color: $vv-seasure-blue;
  }
}

@mixin dimensions($type, $val, $prefix: null) {
  $leader: str-slice($type, 0, 1);
  $vv-prefix: null;

  // Only applies 'vv-' prefix to 'width' styles (i.e. w-20), is only temporary until the 'height' styles are updated to include the 'vv-' prefix.
  @if(equals($type, 'width')) {
    $vv-prefix: 'vv-'
  }

  @if(is-not-null($prefix)) {
    $prefix: #{$prefix}-
  }

  .#{$vv_prefix}#{$prefix}#{$leader}-#{$val} {
    #{to-lower-case($type)}: #{$val}px;
  }

  .#{$prefix}min-#{$leader}-#{$val} {
    min-#{to-lower-case($type)}: #{$val}px;
  }

  .#{$prefix}max-#{$leader}-#{$val} {
    max-#{to-lower-case($type)}: #{$val}px;
  }
}
