// Bootstrap Dropdown Nav Plugin
.collapse {
  display: none;
  &.in {
    display: block;
  }
}
tr.collapse.in {
  display: table-row;
}
tbody.collapse.in {
  display: table-row-group;
}
.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  -webkit-transition-timing-function: ease;
  -o-transition-timing-function: ease;
  transition-timing-function: ease;
  -webkit-transition-duration: .35s;
  -o-transition-duration: .35s;
  transition-duration: .35s;
  -webkit-transition-property: height, visibility;
  -o-transition-property: height, visibility;
  transition-property: height, visibility;
}
.caret,
.advanced_fields span.caret {
  background: url("../img/ddown_2x.png") 0 0;
  background-size: 20px 32px;
  width: 8px;
  height: 10px;
  display: inline-block;
  margin-left: 2px;
}
.caret {
  &.white_caret {
    background-position: 0 21px;
  }
  &.grey_caret {
    background-position: 0 41px;
  }
}
.dropup,
.dropdown {
  position: relative;
}
.dropdown-toggle:focus {
  outline: 0;
}
#my_dd + .dropdown-menu {
  left: auto;
  right: 0;
}
.dropdown-menu {
  &:before {
    left: 20%;
  }
  > .open {
    > a {
      color: $white;
      text-decoration: none;
      background-color: $vv-dark-red;
      outline: 0;
      &.seasure_only {
        background-color: $vv-seasure-blue;
      }
    }
  }
  .active {
    > a,
    > a:hover,
    > a:focus {
      color: $white;
      text-decoration: none;
      background-color: $vv-dark-red;
      outline: 0;
      &.seasure_only {
        background-color: $vv-seasure-blue;
      }
    }
  }
  .disabled > a {
    color: $vv-light-dark-grey;
    &:hover,
    &:focus {
      color: $vv-light-dark-grey;
      text-decoration: none;
      cursor: not-allowed;
      background: transparent none;
    }
  }
}
.open {
  > .dropdown-menu {
    display: block;
  }
  > a {
    outline: 0;
  }
}
.dropdown-menu-right {
  right: 0;
  left: auto;
}
.dropdown-menu-left {
  right: auto;
  left: 0;
}
.dropdown-header {
  display: block;
  padding: 3px 20px;
  line-height: 1.42857143;
  color: $vv-light-dark-grey;
  white-space: nowrap;
}
.dropdown-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 990;
}
.pull-right > .dropdown-menu {
  right: 0;
  left: auto;
}
/* Navbar doesn't exist in the code base */
.dropup .dropdown-menu,
.navbar-fixed-bottom .dropdown .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-bottom: 2px;
}
@include tablet-min {
  .navbar-right {
    .dropdown-menu {
      right: 0;
      left: auto;
    }
    .dropdown-menu-left {
      right: auto;
      left: 0;
    }
  }
}
.dropdown:hover > .dropdown-menu {
  display: block;
}
.open > .dropdown-menu {
  display: block;
}
@include tablet-min {
  .navbar-header {
    float: left;
  }
}
.navbar-collapse {
  overflow-x: visible;
  padding-right: 15px;
  padding-left: 15px;
  border-top: 1px solid transparent;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1);
  -webkit-overflow-scrolling: touch;
  &.in {
    overflow-y: auto;
  }
  @include tablet-min {
    width: auto;
    border-top: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    &.collapse {
      display: block !important;
      height: auto !important;
      padding-bottom: 0;
      overflow: visible !important;
    }
    &.in {
      overflow-y: visible;
    }
  }
}
.navbar-nav {
  margin: 7.5px -15px;
  > li > a {
    padding-top: 10px;
    padding-bottom: 10px;
    line-height: 20px;
  }
  @include sm-tablet {
    .open .dropdown-menu {
      position: static;
      float: none;
      width: auto;
      margin-top: 0;
      background-color: transparent;
      border: 0;
      -webkit-box-shadow: none;
      box-shadow: none;
      > li > a,
      .dropdown-header {
        padding: 5px 15px 5px 25px;
      }
      > li > a {
        line-height: 20px;
        &:hover,
        &:focus {
          background-image: none;
        }
      }
    }
  }
}
.navbar-nav {
  @include tablet-min {
    float: left;
    margin: 0;
    > li {
      float: left;
      > a {
        padding-top: 15px;
        padding-bottom: 15px;
      }
    }
  }
  > li > .dropdown-menu {
    margin-top: 0;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
  }
}

// Custom styling for ship type menu dropdowns.
.advanced_dropdowns {
  li:not(.dimmed) {
    span.advanced_dropdown.mobile_open {
      background: $error-dark-red url("../img/dropdownactive.png") right no-repeat;
      color: $white;
    }

    div:not(.new-menu-item-container).advanced_dropdown.mobile_open {
      display: block;
    }
  }
  select.ship_type_select {
    width: 200px;
    position: relative;
    z-index: 30000;
  }
  .ship_type_menu ul, .advanced_dropdown .ship_type_menu ul li {
    width: 100%;
  }
}
.advanced_fields.search_types .ship_type_menu ul li {
  margin: 0;
  padding: 0;
}

// Bootstrap-submenu v1.2.7 (http://vsn4ik.github.io/bootstrap-submenu)
// Copyright 2014 Vasily A. (https://github.com/vsn4ik)
// Licensed under MIT (https://github.com/vsn4ik/bootstrap-submenu/blob/master/LICENSE)
.dropdown-submenu:hover > a:after {
  width: 100px;
  height: 40px;
  position: absolute;
  right: -20px;
  bottom: -10px;
  content: "";
  z-index: 3;
}
.ship_type_menu .dropdown-menu > li:hover > a,
.dropdown-menu > .dropdown-submenu:hover > a {
  color: $vv-dark-red;
  background-color: $dark-grey-yellow;
  text-decoration: underline;
}
.ship_type_menu .dropdown-menu.disabled > li:hover > a,
.ship_type_menu .dropdown-menu > li.disabled:hover > a,
.dropdown-menu > .dropdown-submenu.disabled:hover > a {
  color: $vv-light-dark-grey;
  background-color: $lightest-mid-grey;
  text-decoration: none;
}
.dropdown-menu > .dropdown-submenu:hover > a,
.dropdown-menu > .dropdown-submenu > a:focus,
.dropdown-menu > .dropdown-submenu.open > a {
  color: $vv-dark-red;
  background-color: $dark-grey-yellow;
}
.dropdown-submenu .dropdown-menu {
  top: 5px;
  left: 100%;
  margin-top: -6px;
  border-top-left-radius: 0
}
/* Positions submenu to right */
.dropdown-submenu {
  position: relative
}
.dropdown-submenu-ship-type {
  > .dropdown-submenu-ship-type-link {
    float: left;
    width: 78%;
    padding-left: 2%;
    height: 100%;
    padding-top: 3px;
    padding-bottom: 3px;
    overflow: hidden;
    text-overflow: ellipsis;
    position: relative;
    z-index: 4;

    &.nochild {
      width: 80%;
      padding-right: 20%;
    }
  }
  > .caret_holder {
    width: 10%;
    padding-top: 7px;
    padding-bottom: 5px;
    padding-right: 10%;
    float: right;
  }
}
a.dropdown-submenu-ship-type {
  &:hover > span {
    text-decoration: underline;
  }
  > .caret_holder > .caret {
    background-size: 20px;
  }
}
.dropdown-submenu:hover > .dropdown-submenu-ship-type > .caret_holder > .caret,
.dropdown-submenu.open > .dropdown-submenu-ship-type > .caret_holder > .caret {
  background-image: url("../img/ddown_2x.png");
  background-position: 8px 0;
  &.white_caret {
    background-position: 8px 8px;
  }
}
.vv_at .ship_type_select {
  max-width: 204px;
}
@include sm-desktop {
  .dropdown-menu, .ship_type_select {
    max-width: 300px;
  }
}
@include tablet {
  .dropdown-menu {
    max-width: 200px;
  }
  .advanced_dropdown select.ship_type_select {
    max-width: 184px;
  }
  .vv_at .dropdown-menu {
    max-width: 170px;
  }
  .vv_at .advanced_dropdown select.ship_type_select {
    max-width: 154px;
  }
}
@media (min-width: 768px) {
  .dropup .dropdown-submenu .dropdown-menu, .navbar-fixed-bottom .dropdown-submenu .dropdown-menu {
    top: auto;
    bottom: 0;
    margin-top: 0;
    margin-bottom: -6px;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 0
  }
}
@media (max-width: 767px) {
  .dropdown-submenu .dropdown-menu {
    left: 50%;
  }
}
@media (max-width: 520px) {
  .dropdown-submenu .dropdown-menu {
    left: 10%;
    position: relative;
  }
  .advanced_fields.search_types {
    width: 160px !important;
  }
}
// Additional
.advanced_fields.search_types.search_ship_types .ship_type_menu ul li a {
  width: 100%;
  margin: 0;
  padding: 0;
  text-align: right;
  overflow: hidden;
}
.search_ship_types ul.dropdown-menu {
  background-color: $light-cream-yellow;
  box-shadow: none;
  border-color: $lighter-dark-grey;
  padding-top: 0;
  padding-bottom: 0;
}
.disabled .search_ship_types ul.dropdown-menu {
  background-color: $lightest-mid-grey;
}
.search_ship_types {
  > .ship_type_menu > ul.dropdown-menu {
    border-top-color: $vv-red;
  }
  ul.dropdown-menu li {
    height: auto;
    padding: 0;
  }
}
.submenu_padding {
  max-width: 200% !important;
  width: -webkit-calc(100% + 50px);
  width: expression(100% + 50px);
  width: -moz-calc(100% + 50px);
  width: -o-calc(100% + 50px);
  width: calc(100% + 50px);
  height: -webkit-calc(100% + 50px);
  height: expression(100% + 50px);
  height: -moz-calc(100% + 50px);
  height: -o-calc(100% + 50px);
  height: calc(100% + 50px);
  min-height: 80px;
  top: -2px;
  left: -5px;
  position: absolute;
  z-index: -1;
  @include tablet {
    width: -webkit-calc(100% + 40px);
    width: expression(100% + 40px);
    width: -moz-calc(100% + 40px);
    width: -o-calc(100% + 40px);
    width: calc(100% + 40px);
    height: -webkit-calc(100% + 40px);
    height: expression(100% + 40px);
    height: -moz-calc(100% + 40px);
    height: -o-calc(100% + 40px);
    height: calc(100% + 40px);
    min-height: 70px;
    top: -2px;
    left: -20px;
  }
  @include sm-tablet {
    width: -webkit-calc(100% + 30px);
    width: expression(100% + 30px);
    width: -moz-calc(100% + 30px);
    width: -o-calc(100% + 30px);
    width: calc(100% + 30px);
    height: -webkit-calc(100% + 30px);
    height: expression(100% + 30px);
    height: -moz-calc(100% + 30px);
    height: -o-calc(100% + 30px);
    height: calc(100% + 30px);
    min-height: 70px;
    top: -2px;
    left: -15px;
  }
}
