@import "colors";
// Screen breakpoints
$screen-width-sm-desktop: 1200px;
$screen-width-tablet: 1000px;
$screen-width-sm-tablet: 790px;
$screen-width-mobile: 560px;
$screen-width-sm-mobile: 350px;

// Container widths
$container-width-default: 1000px;
$container-width-desktop: 1200px;
$container-width-sm-desktop: $container-width-default;
$container-width-tablet: 760px;
$container-width-sm-tablet: 560px;
$container-width-mobile: 100%;

// Fonts
$default-font-family: "Open Sans", Helvetica, Arial, sans-serif;

// Font Sizes
$font-size-xxs: 0.60em;
$font-size-xs: 0.77em;
$font-size-s: 0.9em;
$font-size-default: 14px;
$font-size-m: 1.1em;
$font-size-l: 1.2em;
$font-size-xl: 1.3em;
$font-size-xxl: 1.4em;
$font-size-xxxl: 1.5em;
$font-size-xxxxl: 1.6em;

// Borders
$border-radius: 3px;
$border-default: 1px solid $vv-darkest-mid-grey;
