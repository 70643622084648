@import '../abstracts/colors';
@import '../abstracts/mixins';

@include sm-tablet {
  #responsive_nav_con {
    position: fixed;
    display: none;
    right: 0;
    top: 0;
    height: 100%;
    width: 240px;
    #responsive_nav > ul > li {
      > a {
        color: $white;
        display: block;
        padding: 10px 25px;
        font-size: 14px;
        position: relative;
        &:hover {
          background-color: $vv-dark-red;
          color: $white;
        }
        .caret {
          position: relative;
          top: 5px;
          float: right;
        }
      }
      > ul.dropdown-menu {
        position: relative;
        background-color: transparent;
        width: 100%;
        border: 0;
        left: 0;
        box-shadow: none;
        max-width: 100%;
        > li {
          > a {
            padding: 10px 25px 10px 40px;
            color: $white;
            &:hover {
              background-color: $vv-red;
              color: $white;
            }
          }
        }
      }
    }
    .rm_anim &, .rm_vis & {
      z-index: 0;
      display: block;
    }
  }
  #everythingbarthefooter {
    .rm_anim & {
      transition: transform .25s ease-in-out;
      -webkit-transition: -webkit-transform .25s ease-in-out;
    }

    .rm_anim.left & {
      transform: translate3d(-240px, 0, 0);
      -webkit-transform: translate3d(-240px, 0, 0);
    }

    .rm_anim.right & {
      transform: translate3d(240px, 0, 0);
      -webkit-transform: translate3d(240px, 0, 0);
    }

    .rm_vis & {
      position: relative;
      right: 240px;
    }
  }
  #responsive_nav_trigger {
    padding: 10px;
    .burger {
      display: block;
      width: 30px;
      max-width: 20px;
      height: 4px;
      background-color: $white;
      margin-bottom: 2px;
      border-radius: 3px;
    }
  }
}
@include tablet-min {
  #responsive_nav_con {
    display: none;
  }
}
.dropdown-menu {
  position: absolute;
  top: 100%;
  left: -1px;
  z-index: 5000;
  display: none;
  float: left;
  padding: 5px 0;
  margin: -1px 0 0;
  text-align: left;
  list-style: none;
  background-color: $white;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, .15);
  -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
  box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
  &:after {
    border: 6px rgba(255, 255, 255, 0);
    border-bottom-color: $white;
    margin-left: -6px;
  }
  &:before {
    margin-left: -7px;
    bottom: 100%;
    left: 90%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }
  &.pull-right {
    right: 0;
    left: auto;
  }
  > li {
    > a {
      display: block;
      padding: 5px 20px;
      clear: both;
      font-weight: normal;
      line-height: 1.42857143;
      color: $vv-dark-red;
      white-space: nowrap;
      &.seasure_only {
        color: $vv-seasure-blue;

        &:hover,
        &:focus {
          background-color: $vv-seasure-blue;
        }
      }

      &:hover,
      &:focus {
        color: $white;
        text-decoration: none;
        background-color: $vv-dark-red;
      }
    }
    > .open > a {
      color: $white;
      text-decoration: none;
      background-color: $vv-dark-red;
      outline: 0;
      &.seasure_only {
        background-color: $vv-seasure-blue;
      }
    }
    > .active > a {
      &.seasure_only {
        background-color: $vv-seasure-blue;
      }
    }
  }
  .divider {
    height: 1px;
    margin: 9px 0;
    overflow: hidden;
    background-color: $lighter-mid-grey;
  }
}
.nav {
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
  > li {
    position: relative;
    display: block;
    > a {
      position: relative;
      display: block;
      padding: 20px 10px;
      &:hover,
      &:focus {
        color: $white;
        text-decoration: none;
      }
      > img {
        max-width: none;
      }
    }
    &.disabled > a {
      color: $vv-light-dark-grey;
      &:hover,
      &:focus {
        color: $vv-light-dark-grey;
        text-decoration: none;
        background-color: transparent;
        cursor: not-allowed;
      }
    }
  }
  a {
    color: $white;
  }
  .open > a,
  .open > a:hover,
  .open > a:focus {
    background: $vv-dark-red repeat-x center;
  }
}
.new_header .nav ul li {
  display: block;
  background-color: transparent;
  margin: 0;
  a {
    padding: 5px 15px;
    &:active {
      background-color: $white;
      color: $vv-dark-red;
    }
    &:hover,
    &.hover {
      background-color: $vv-dark-red;
      color: $white;
    }
  }
  @media screen and (max-width: 360px) { // could probably be changed to sm-mobile/350px breakpoint
    margin-bottom: 10px;
  }
}
.header {
  .main_nav {
    height: auto;
    padding: 0;
    margin: 0;
    float: right;
    > li {
      float: left;
      font-weight: normal;
      margin-right: 0;
      &:hover {
        background: $vv-dark-red repeat-x center;
      }
      .dropdown-menu li {
        float: none;
        color: $vv-dark-red;
      }
      &.burger-nav-btn {
        float: right;
        width: 40px;
      }
      @include mobile {
        margin-right: 5px;
      }
    }
    @include desktop {
      margin-top: 4px;
    }
  }
}
