/* Flag Images
 * Found on the several pages around the website
 */
.flag_image {
  height: 11px;
  width: 16px;
  background: url(../img/flags_2x.png) no-repeat;
  background-size: 16px;
  display: inline-block;
}

.flag_heading {
  display: inline-block;
  height: 14px;
  width: 14px;
  margin-bottom: -3px;
}

img.flag,
th.stoppages,
.stoppages {
  cursor: default;
}

a img.flag {
  cursor: pointer;
}
