@each $type in (auto, hidden, visible, scroll) {
  .overflow#{$type} {
    overflow: $type;

    &-x {
      overflow-x: $type
    }

    &-y {
      overflow-y: $type
    }
  }
}

.scrolling {
  &-touch {
    -webkit-overflow-scrolling: touch
  }

  &-auto {
    -webkit-overflow-scrolling: auto;
  }
}
