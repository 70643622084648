@import '../abstracts/colors';

.upgrade_banner {
  img {
    margin: 0 35% 0 35%;
  }

  *:last-child {
    margin-bottom: 0;
  }
}
