@import '../abstracts/colors';
@import '../abstracts/mixins';

.header_con {
  margin: 0 auto;
  font-weight: 500;
  width: 100%;
  height: auto;
  min-height: 60px;

  &.at {
    min-height: 0;
  }

  .header {
    height: auto;

    .logo {
      svg {
        display: block;
        max-width: 250px;
        max-height: 45px;
        @include mobile {
          margin-left: auto;
          margin-right: auto;
        }
        @include tablet {
          max-width: 205px;
        }
      }
    }

    &.red {
      fill: $vv-red;
    }
  }

  .logo_map svg {
    max-height: 40px;
    margin-top: 2px;
  }
}

@include mobile {
  #menu_subnav {
    .button {
      margin-bottom: 10px;
    }
  }
}

