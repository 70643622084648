@import '../abstracts/colors';

#free_trial_notice {
  .ft-top {
    text-transform: uppercase;
    font-size: 3.5em;
    color: $white;
    line-height: 42px;
  }
  .ft-middle {
    font-weight: bold;
    color: $white;
    text-transform: uppercase;
    font-size: 4.9em;
    line-height: 49px;
  }
  .ft-bottom {
    color: $white;
    font-size: 1.67em;
    line-height: 40px;
    position: relative;
    top: 6px;
  }
  .ft-copyright {
    text-align: center;
    color: $white;
    display: block;
    margin-top: 5px;
    margin-bottom: 20px;
  }

}
.free_notice_buttons {
  width: 100%;
  .link {
    border-color: transparent;
    background-color: transparent;
    color: $vv-dark-red;
    padding-left: 0;
    &:hover, &:active {
      color: $vv-darkest-red;
    }
    &:focus {
      text-decoration: none;
      outline: thin dotted;
      outline: 5px auto -webkit-focus-ring-color;
      outline-offset: -2px;
    }
  }
}
