@import '../abstracts/colors';

table td > .abs_popup_guide {
  position: absolute;
  top: 0;
  padding-left: 3px;
}

.popup_container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  overflow: auto;
  transition: opacity 1s ease;

  &:not(.hide) {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &.blackout {
    background: rgba(0, 0, 0, 0.2);
  }

  &.fade-out {
    opacity: 0;
  }

  .popup {
    position: relative;
    box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.5);
    width: 500px;
    background: $white;

    .close-button {
      appearance: none;
      background: transparent;
      border: none;
      color: $white;
      position: absolute;
      right: 0;
      top: 0;
      padding: 5px 15px;
      outline: none;
      opacity: 0.7;
      transition: opacity 0.2s ease;
      z-index: 9;

      &:hover {
        opacity: 1;
      }

      span {
        color: $white;
        font-size: 20px;
      }
    }

    .popup-title {
      background: $vv-red;
      padding: .7em 1em;
      position: relative;
      user-select: none;

      .title {
        margin: 2px;
        cursor: default;
        user-select: none;
        color: $white;
      }
    }

    .popup-loading, .popup-content .popup-loading {
      @extend .ac;
      @extend .vv-my-20;
      margin-left: auto;
      margin-right: auto;

      img {
        width: 50px;
        margin: 50px;
      }
    }

    .popup-content {
      position: relative;
      display: block;
      overflow: auto;

      &.hide {
        display: none;
      }
    }
  }
}

body.popup-open {
  overflow: hidden;
  width: 100%;
}

body.disable-scrolling {
  position: relative !important;
}

#popup {
  .options {
    background: $vv-light-grey;
    padding: 20px;
  }

  input.document_name {
    border: 1px solid $lighter-dark-grey;
    height: 40px;
    padding: 0 10px;
  }

  #file_password {
    margin-left: 58px;
    margin-top: 20px;
    width: calc(100% - 80px);
    border: 1px solid $lighter-dark-grey;
    height: 40px;
    padding: 0 10px;
  }

  &_container {
    padding-top: 0.25em;
    padding-bottom: 0.25em;
  }

  &_body {
    width: 100%;
    padding-left: 2px;
    padding-right: 2px;
    box-sizing: border-box;
  }

  &_enquiry {
    width: 100%;
    height: 100px;
    max-height: 150px;
    min-height: 50px;
    box-sizing: border-box;
    resize: none;

    &:focus {
      outline-color: $dimmed-grey;
    }
  }
}
