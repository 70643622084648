@import '../abstracts/mixins';
@import '../abstracts/colors';

.chart {
  background: url('../img/roundel-transparent.png');
  background-size: cover;
}

.chart_wrapper {
  width: 100%;
  height: auto;
  min-height: 400px;

  hr {
    margin-bottom: 0;
    height: auto;
  }
}

table.tableHeader div.highcharts-contextmenu,
table.tableHeader div.highcharts-menu {
  height: auto;
}

.highcharts-menu > hr {
  margin-bottom: 0;
  height: auto;
}

.chart_aside {
  border: 1px solid $vv-darkest-mid-grey;
  padding: 10px;
  overflow-y: auto;

  .buffer {
    height: 10px;
    width: 100%;
  }

  .sub_title {
    color: #878787;
  }

  &.ha {
    overflow-y: visible;
    max-height: none;
  }

  &.full {
    height: 100%;
    min-height: 100%;
  }
}

@include tablet {
  .chart_aside.tablet_ha {
    overflow-y: visible;
    max-height: none;
    min-height: 0;
    height: auto;
  }
}

@include sm-tablet {
  .chart_aside,
  .plain_aside {
    min-height: 0;
    height: auto;
  }

  #responsive_nav_trigger {
    float: none;
  }
}

.chart_container {
  touch-action: none;
}

.chart_placeholder {
  height: 100%;
  width: 100%;
  border: 1px solid $vv-darkest-mid-grey;
}

.chart_flag_dot {
  width: 10px;
  height: 10px;
  background-color: $vv-red;
  display: block;
  border-radius: 10px;
}

#everythingbarthefooter div.sample_graph {
  min-height: 400px;
  background: no-repeat top;
  background-size: 100% auto;
  padding-top: 60px;
}

.options_box {
  box-sizing: border-box;
  width: 80%;
}
