@import '../abstracts/colors';

.two_factor_authentication {
  line-height: 1.5;

  .loading {
    margin-top: 50px;
    margin-bottom: 50px;

    img {
      height: 50px;
    }
  }

  h3 {
    color: $vv-dark-teal;
    font-weight: bold;
    padding: 0;
    margin: 0;
    font-size: 16px;
  }

  .two_fa_orbs {
    float: right;
    user-select: none;

    @media (max-width: 560px) {
      float: none;
      margin: 0 auto 20px auto;
      text-align: center;
    }

    li:nth-of-type(1) {
      margin: 0;
    }

    li {
      background: $vv-dark-grey;
      color: $white;
      width: 18px;
      height: 18px;
      font-size: 10px;
      line-height: 18px;
      border-radius: 18px;
      display: inline-block;
      text-align: center;
      position: relative;
      margin-left: 10px;
      transition: all 0.4s ease;

      &.active {
        background: $vv-red;
      }

      &:before {
        content: ' ';
        position: absolute;
        top: 8px;
        left: 100%;
        height: 2px;
        width: 20px;
        background: $vv-dark-grey;
      }

      &:last-child:before {
        display: none;
      }
    }
  }

  .code_copy {
    display: block;
    margin-left: auto;
    margin-right: auto;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 24px;
    text-align: center;
    padding: 10px;
    width: 350px;

    @media (max-width: 768px) {
      font-size: 20px;
      width: 275px;
    }

    img {
      display: none;

      @media (max-width: 768px) {
        height: 18px;
      }
    }

    &.selectable {
      border: dashed 2px $vv-darkest-mid-grey;

      img {
        display: block;
        margin-left: 10px;
      }
    }
  }

  .copy_icon {
    height: 25px;
    padding-top: 8px;
  }

  .request_email {
    width: 140px;
  }

  .desktop_icon, .mobile_icon {
    height: 110px;
  }

  .platform_icon {
    height: 50px;
  }

  .options {
    margin-left: 20px;
    list-style: disc;

    li {
      padding-bottom: 10px;
    }
  }

  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .pb {
    border-top: dashed 1px $vv-light-dark-grey;
    height: 1px;
  }

  input[type=text].twofa_input {
    text-align: center;
    letter-spacing: 5px;
    font-size: 1.6em;
    border-radius: 3px;
    width: 200px;

    &::placeholder {
      color: $dimmed-grey;
      opacity: 1;
      font-size: 0.8em;
      letter-spacing: normal;
    }
  }
}
