@import "../../abstracts/variables";
@import "../../abstracts/colors";

/* CSS Switch Checkbox */
input.switch:empty {
  margin-left: -9999px;
}

input.switch:empty ~ label {
  position: relative;
  float: left;
  line-height: 1.6em;
  text-indent: 4em;
  margin: 0.2em 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

input.switch:empty ~ label:before,
input.switch:empty ~ label:after {
  position: absolute;
  display: block;
  top: 0.2em;
  bottom: 0;
  left: 0;
  content: ' ';
  width: 3.6em;
  background-color: $vv-darkest-mid-grey;
  border-radius: 1em;
  -webkit-transition: all 100ms ease-in;
  transition: all 100ms ease-in;
}

input.switch:empty ~ label:before {
  height: 1.6em;
  max-height: 1.6em;
}

input.switch:empty ~ label:after {
  height: 1.296em;
  max-height: 1.296em;
  width: 1.3em;
  top: 0.34em;
  margin-left: 0.2em;
  background-color: $white;
  border-radius: 1em;
}

input.switch:empty:checked ~ label:before {
  background-color: $vv-dark-red;
}

input.switch:empty:checked[disabled="disabled"] ~ label:before, input.switch.dimmed:empty:checked ~ label:before {
  background-color: $vv-off-red;
}

input.switch:empty:checked ~ label:after {
  margin-left: 2.1em;
}
