.ms-options-wrap > button:focus, .ms-options-wrap > button {
  width: 100% !important;
  border: 1px solid #CCCCCC !important;
  background-color: #FAFAFA !important;
  padding: 2px 4px !important;
  margin-top: 0 !important;
  font: inherit !important;
  color: #000000 !important;
}

.ms-options-wrap > button:focus {
  outline: -webkit-focus-ring-color auto 5px;
}

.ms-options-wrap > .ms-options,
.ms-options-wrap > .ms-options > ul label,
.ms-options-wrap > .ms-options > .ms-search input {
  width: 100% !important;
}
