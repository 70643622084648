@import "../../abstracts/variables";
@import "../../abstracts/colors";
//
// Styled Radio Buttons
//
.radio-styled input[type="radio"] {
  position: absolute;
  opacity: 0;
  +label:hover:before {
    border-color: $vv-dark-red;
  }
  + label:before {
    content: '';
    background: $white;
    border-radius: 100%;
    border: 2px solid $lighter-dark-grey;
    display: inline-block;
    width: 15px;
    height: 15px;
    position: relative;
    top: 0;
    margin-right: 1em;
    vertical-align: middle;
    cursor: pointer;
    text-align: center;
    transition: all 250ms ease;
  }
  &:checked + label:before {
    border-color: $vv-dark-red;
    background-color: $vv-dark-red;
    box-shadow: inset 0 0 0 2px $white;
  }
  &:focus + label:before {
    outline: none;
    border-color: $vv-dark-red;
  }
  &:disabled + label:before {
    box-shadow: inset 0 0 0 2px $vv-dark-red;
    border-color: darken($vv-dark-red, 25%);
    background: darken($vv-dark-red, 25%);
  }
  + label:empty:before {
    margin-right: 0;
  }
  + label.seasure_only:before {
    border-color: $vv-seasure-blue;
    opacity: 0.5;
  }
  &:checked + label.seasure_only:before {
    border-color: $vv-seasure-blue;
    background-color: $vv-seasure-blue;
    opacity: 1;
  }
}
// Override for Default14 issue
.default14 .radio-styled input[type="radio"] + label:hover:before {
  border: 2px solid $vv-dark-red !important;
}
// fix for seasure_only Default14 issue
.default14 .radio-styled input[type="radio"] + label.seasure_only:hover:before {
  border: 2px solid $vv-seasure-blue !important;
  opacity: 1;
}
//
// Large Radio Buttons
//
.radio-lg {
  position: relative;
  label {
    position: relative;
    line-height: 2em;
    min-height: 7em;
    border: 1px solid $lighter-mid-grey;
    border-radius: 3px;
    text-align: center;
    font-weight: bold;
    text-transform: capitalize;
    cursor: pointer;
    font-size: 1.145em;
    span.discount {
      position: absolute;
      top: 0;
      right: 0;
      color: $white;
      background-color: $vv-dark-teal;
      border-bottom-left-radius: 3px;
      font-size: 0.8em;
      margin: 0;
      padding: 0 1em;
      line-height: 1.5em;
      text-transform: none;
    }
    span {
      font-weight: normal;
      font-size: 0.855em;
    }
  }
  input[type="radio"] {
    bottom: 10px;
    position: absolute;

    &:checked + label {
      color: $vv-dark-red;
      border-color: $vv-dark-red;
      > span {
        color: initial;
      }
      > span.discount {
        color: $white;
      }
    }
  }
  // Override Styled Radio buttons.
  &.radio-styled input[type="radio"] {
    top: 1em;
    + label:before {
      display: block;
      top: 6em;
      left: calc(50% - 0.5em);
    }
  }
}
