@import '../abstracts/colors';
/* VV Cookies Plugin */

/* Cookie Banner */

#cookies {
    position: fixed;
    z-index: 9999;
    bottom: 0;
    background-color: rgba($darker-grey, 0.9);
    display: none;
}

#cookies .panel {
    position: relative;
    z-index: 1;
}

#cookies .panel p {
    color: $white;
}

#cookies .panel a:not(#informedConsent) {
    color: $white;
    font-weight: bold;
    &:hover, &:active, &:focus {
        color: $darker-mid-grey;
    }
}

#cookies #informedConsent {
    padding: 5px 15px;
    &:hover {
        background-color: $white;
        color: $vv-dark-red;
    }
}

/* Cookie Settings Panel */

#cookieSettingsPanel.ui-dialog, #cookieSettingsPanel.ui-dialog-content {
    max-width: 100%;
}

#cookieSettingsPanel .tabstrip, #cookieSettingsPanel .tabbing {
    border: 0;
    padding: 0;
    margin: 0;
    display: block;
}

#cookieSettingsPanel .tabstrip a, #cookieSettingsPanel .tabbing a {
    float: none;
    display: block;
    height: auto;
    padding: 35px;
    padding-left: 60px;
    border: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    margin: 0;
    background-color: $lightest-mid-grey;
    background-position: 25px center;
    background-repeat: no-repeat;
    background-size: 25px 25px;
    overflow: hidden;
}

#cookieSettingsPanel .tabstrip a[data-id="privacy"], #cookieSettingsPanel .tabbing a[data-id="privacy"] {
    background-image: url('../img/cookies_assets/privacy-icon.png');
}

#cookieSettingsPanel .tabstrip a[data-id="privacy"].active, #cookieSettingsPanel .tabbing a[data-id="privacy"].active {
    background-image: url('../img/cookies_assets/privacy-icon-red.png');
}

#cookieSettingsPanel .tabstrip a[data-id="necessary-cookies"], #cookieSettingsPanel .tabbing a[data-id="necessary-cookies"] {
    background-image: url('../img/cookies_assets/strictly-necessary-icon.png');
}

#cookieSettingsPanel .tabstrip a[data-id="necessary-cookies"].active, #cookieSettingsPanel .tabbing a[data-id="necessary-cookies"].active {
    background-image: url('../img/cookies_assets/strictly-necessary-icon-red.png');
}

#cookieSettingsPanel .tabstrip a[data-id="performance-cookies"], #cookieSettingsPanel .tabbing a[data-id="performance-cookies"] {
    background-image: url('../img/cookies_assets/performance-icon.png');
}

#cookieSettingsPanel .tabstrip a[data-id="performance-cookies"].active, #cookieSettingsPanel .tabbing a[data-id="performance-cookies"].active {
    background-image: url('../img/cookies_assets/performance-icon-red.png');
}

#cookieSettingsPanel .tabstrip a[data-id="functional-cookies"], #cookieSettingsPanel .tabbing a[data-id="functional-cookies"] {
    background-image: url('../img/cookies_assets/functionality-icon.png');
}

#cookieSettingsPanel .tabstrip a[data-id="functional-cookies"].active, #cookieSettingsPanel .tabbing a[data-id="functional-cookies"].active {
    background-image: url('../img/cookies_assets/functionality-icon-red.png');
}

#cookieSettingsPanel .tabstrip a + a, #cookieSettingsPanel .tabbing a + a {
    border-top: 1px dotted $light-mid-grey;
}

#cookieSettingsPanel .tabstrip a.active, #cookieSettingsPanel .tabbing a.active,
#cookieSettingsPanel .tabstrip a:hover, #cookieSettingsPanel .tabbing a:hover {
    background-color: $white;
    color: $darker-grey;
}

#cookieSettingsPanel .panel_controls {
    border-top: 1px solid $vv-red;
}
