@import '../abstracts/colors';

// Dropzone
@keyframes passing-through {
  0% {
    opacity: 0;
    transform: translateY(40px);
  }
  30%, 70% {
    opacity: 1;
    transform: translateY(0px);
  }
  100% {
    opacity: 0;
    transform: translateY(-40px);
  }
}
@keyframes slide-in {
  0% {
    opacity: 0;
    transform: translateY(40px);
  }
  30% {
    opacity: 1;
    transform: translateY(0px);
  }
}
@keyframes slide-in {
  0% {
    opacity: 0;
    transform: translateY(40px);
  }
  30% {
    opacity: 1;
    transform: translateY(0px);
  }
}
@keyframes pulse {
  0% {
    transform: scale(1);
  }
  10% {
    transform: scale(1.1);
  }
  20% {
    transform: scale(1);
  }
}
@keyframes pulse {
  0% {
    transform: scale(1);
  }
  10% {
    transform: scale(1.1);
  }
  20% {
    transform: scale(1);
  }
}
.dropzone,
.dropzone * {
  box-sizing: border-box;
}
.dropzone {
  background: white none repeat scroll 0 0;
  border: 2px solid $vv-darkest-mid-grey;
  min-height: 150px;
  display: block;
  width: 100%;
  padding: 10px;
  .dz-message {
    font-size: 18px;
    color: $lighter-dark-grey;
    margin: 2em 0;
    text-align: center;
  }
  &.dz-clickable {
    cursor: pointer;
    * {
      cursor: default;
    }
    .dz-message,
    .dz-message * {
      cursor: pointer;
    }
    .dz-message {
      .dn {
        display: none;
      }
    }
  }
  &.dz-drag-hover {
    border-style: solid;
    .dz-message {
      opacity: 0.5;
    }
  }
  .dz-preview {
    display: inline-block;
    margin: 15px;
    min-height: 100px;
    position: relative;
    vertical-align: top;
    &:hover {
      z-index: 1000;
      .dz-details {
        opacity: 1;
      }
      .dz-image img {
        filter: blur(8px);
        transform: scale(1.05, 1.05);
      }
    }
    &.dz-file-preview {
      background: white none repeat scroll 0 0;
      .dz-image {
        background: rgba(0, 0, 0, 0) linear-gradient(to bottom, $lightest-mid-grey, $light-mid-grey) repeat scroll 0 0;
      }
      .dz-details {
        opacity: 1;
        transition: opacity 0.2s linear 0s;
      }
    }
    &.dz-success .dz-success-mark {
      animation: 3s cubic-bezier(0.77, 0, 0.175, 1) 0s normal none 1 running passing-through;
    }
    &.dz-error {
      .dz-error-mark {
        animation: 3s cubic-bezier(0.77, 0, 0.175, 1) 0s normal none 1 running slide-in;
        opacity: 1;
      }
      .dz-error-message .disB {
        display: block;
      }
      &:hover .dz-error-message {
        opacity: 1;
        pointer-events: auto;
      }
    }
    &.dz-processing .dz-progress {
      opacity: 1;
      transition: all 0.2s linear 0s;
    }
    &.dz-complete .dz-progress {
      opacity: 0;
      transition: opacity 0.4s ease-in 0s;
    }
    &:not(.dz-processing) .dz-progress {
      animation: 6s ease 0s normal none infinite running pulse;
    }
    .dz-remove {
      border: medium none;
      cursor: pointer;
      display: block;
      font-size: 14px;
      text-align: center;
      &:hover {
        text-decoration: underline;
      }
    }
    .dz-details {
      color: rgba(0, 0, 0, 0.9);
      left: 0;
      line-height: 150%;
      max-width: 100%;
      min-width: 100%;
      opacity: 0;
      padding: 2em 1em;
      position: absolute;
      text-align: center;
      top: 0;
      z-index: 20;
      .dz-size {
        margin-bottom: 1em;
      }
      .dz-filename {
        font-size: 16px;
        white-space: nowrap;
        &:hover span {
          background-color: rgba(255, 255, 255, 0.8);
          border: 1px solid rgba(200, 200, 200, 0.8);
        }
        &:not(:hover) {
          overflow: hidden;
          text-overflow: ellipsis;
          span {
            border: 1px solid transparent;
          }
        }
      }
      .dz-filename,
      .dz-size {
        span {
          background-color: rgba(255, 255, 255, 0.4);
          padding: 0 0.4em;
        }
      }
      .dz-image {
        display: block;
        height: 120px;
        overflow: hidden;
        position: relative;
        width: 120px;
        z-index: 10;
        img {
          display: block;
        }
      }
      .dz-success-mark,
      .dz-error-mark {
        display: block;
        left: 50%;
        margin-left: -27px;
        margin-top: -27px;
        opacity: 0;
        pointer-events: none;
        position: absolute;
        top: 50%;
        z-index: 500;
        svg {
          display: block;
          height: 54px;
          width: 54px;
        }
      }
      .dz-progress {
        background: rgba(255, 255, 255, 0.9) none repeat scroll 0 0;
        height: 16px;
        left: 50%;
        margin-left: -40px;
        margin-top: -8px;
        opacity: 1;
        overflow: hidden;
        pointer-events: none;
        position: absolute;
        top: 50%;
        width: 80px;
        z-index: 1000;
        .dz-upload {
          background: rgba(0, 0, 0, 0) linear-gradient(to bottom, $vv-light-dark-grey, $vv-dark-grey) repeat scroll 0 0;
          bottom: 0;
          left: 0;
          position: absolute;
          top: 0;
          transition: width 300ms ease-in-out 0s;
          width: 0;
        }
      }
      .dz-error-message {
        background: rgba(0, 0, 0, 0) linear-gradient(to bottom, $error-mid-red, $vv-dark-red) repeat scroll 0 0;
        color: white;
        display: none;
        font-size: 13px;
        left: -10px;
        opacity: 0;
        padding: 0.5em 1.2em;
        pointer-events: none;
        position: absolute;
        top: 130px;
        transition: opacity 0.3s ease 0s;
        width: 140px;
        z-index: 1000;
        &::after {
          border-bottom: 6px solid $error-mid-red;
          border-left: 6px solid transparent;
          border-right: 6px solid transparent;
          content: "";
          height: 0;
          left: 64px;
          position: absolute;
          top: -6px;
          width: 0;
        }
      }
    }
  }
}
.op03 {
  opacity: 0.3;
}
