@import '../abstracts/colors';

/* Valuation Head Sections
 * Found on the valuation, company and portfolio pages
 */
.valuation_head {
  float: left;
  margin: 0 0 5px;
  clear: both;
  width: 100%;
  .h {
    font-weight: normal;
    font-size: 1.5rem;
  }
  .b {
    font-weight: normal;
    padding: 0 0 0 10px;
    color: $lighter-dark-grey;
  }
}
.link_type {
  vertical-align: -1px;
  background: url(../img/link_types_2x.png) no-repeat;
  background-size: 200px 494px;
  cursor: default;
  a & {
    cursor: pointer;
  }
}
