@import 'abstracts/functions';
@import 'abstracts/mixins';

@import "vendors/bootstrap";
@import "vendors/jquery-ui";
@import "vendors/dropzone";

@import 'base/reset';
@import "base/backgrounds";
@import "base/borders";
@import 'base/typography';
@import "base/icons";
@import 'base/buttons';
@import "base/lists";
@import "base/tables";
@import "base/seasure";
@import "base/transitions";

@import "layout/alignment";
@import "layout/containers";
@import 'layout/grid';
@import "layout/widths";
@import "layout/heights";
@import "layout/display";
@import "layout/flexbox";
@import "layout/positions";
@import 'layout/header';
@import 'layout/navigation';
@import 'layout/footer';
@import 'layout/sidebar';
@import 'layout/forms';

@import "components/login";
@import "components/flags";
@import "components/charts";
@import 'components/popup';
@import "components/tooltips";
@import "components/free_trial_popup";
@import "components/product_announcement_popup";
@import "components/ticker";
@import "components/quick-search";
@import "components/advanced-search";
@import "components/tabstrip";
@import "components/valuation-header-panel";
@import "components/valuation-figures-panel";
@import "components/valuation-promo";
@import "components/micro-map";
@import "components/medi-map";
@import "components/new-tag";
@import "components/notes";
@import "components/notifications";
@import "components/terms";
@import "components/paginator";
@import "components/test-mode-notice";
@import "components/loaders";
@import "components/banners";
@import "components/cookies";
@import "components/alerts";
@import "components/two-fa";
@import "components/risk-icons";

@import "layout/margins";
@import "layout/paddings";

@import "base/print";

@import "utilities/utilities";

@import "fonts/opensans";

@import "../vv-library/src/styles/tooltip";

@import "override";

// Style for adding an overlay background behind modals (ModalBase).
.overlay {
  background-color: rgba(0, 0, 0, .25);
}
