@import '../abstracts/colors';
@import '../abstracts/mixins';
@import '../abstracts/variables';

#product_announcement {
  padding-bottom: 93px;

  &.pb-0 {
    padding-bottom: 0;
  }

  .product-top {
    text-transform: uppercase;
    font-size: 2.4em;
    color: $white;
    line-height: 42px;
  }

  .product-middle {
    font-weight: bold;
    color: $white;
    text-transform: uppercase;
    font-size: 3.8em;
    line-height: 49px;
  }

  .product-bottom {
    color: $white;
    position: relative;
    top: 25px;
  }
}

.product_cta {
  position: absolute;
  bottom: 0;
  background: $white;
  width: 100%;
  left: 0;
  text-align: left;

  .product_cta_text {
    padding: 10px 1.1em 2px;
    display: block;
  }
}

.product_buttons {
  width: 100%;

  .link {
    border-color: transparent;
    background-color: transparent;
    color: $vv-dark-red;
    padding-left: 0;

    &:hover, &:active {
      color: $vv-darkest-red;
    }

    &:focus {
      text-decoration: none;
      outline: thin dotted;
      outline: 5px auto -webkit-focus-ring-color;
      outline-offset: -2px;
    }
  }
}

.product_footer .idwal {
  border-top: 2px solid $lighter-grey;
  margin-top: 10px;
  padding-top: 10px;

  img {
    width: 200px;
  }
}

.popup_container[data-popup-id="cruise-ships"] {
  .popup-content {
    @include mobile {
      /** Mobile is significantly different hence the important height override **/
      height: 430px !important;
    }
  }
}

.cruise-image {
  background-image: url('../img/popups/cruise.jpg');
  background-size: cover;
  height: 100%;
  position: relative;
  user-select: none;

  @include mobile {
    background-image: url('../img/popups/cruise-mobile.png');
  }

  .content {
    padding-left: 30px;

    @include mobile {
      padding-left: 15px;
      padding-right: 15px;
    }

    h2 {
      padding: 22% 0 0;
      margin: 0;
      text-transform: uppercase;
      color: $white;

      @include mobile {
        padding-top: 185px;
      }

      &:after {
        display: none;
      }
    }

    .heading {
      padding: 0;
      margin: 0;
      font-size: 48px;
      text-transform: uppercase;
      color: $white;
      font-weight: bold;

      @include mobile {
        font-size: 44px;
      }
    }

    .description {
      padding: 0;
      margin: 0;
      color: $white;
      font-size: 14px;

      @include mobile {
        margin-top: 10px;
        margin-bottom: 40px;

        br {
          display: none;
        }
      }
    }
  }

  .dsa {
    @include mobile {
      position: absolute;
      bottom: -5px;
      left: 0;
      width: 100%;
    }
  }

  .foot {
    position: absolute;
    box-sizing: border-box;
    bottom: 0;
    left: 0;
    width: 100%;
    padding-bottom: 20px;
    padding-left: 15px;
    padding-right: 15px;

    a:first-child {
      color: $white;
    }

    @include mobile {
      text-align: center;
      margin-top: 10px;
      bottom: 20px;

      .fr {
        float: none;
      }
    }
  }
}

[data-popup-id="seven-to"] {
  text-align: center;

  &.popup_container .popup .close-button span{
    color: $darker-grey;
  }

  .popup-content {
    padding: 40px 65px 30px 65px;

    h1 {
      color: $vv-red;
      font-size: 2.5em;
      font-weight: bold;
      text-transform: uppercase;
      margin: 0;
      padding: 0;

      &:after {
        display: none;
      }
    }

    .heading {
      font-size: 1.5em;
      color: $darker-grey;
      font-weight: bold;
      margin: 0;
      padding: 0;
    }

    .description {
      color: $darker-grey;
    }

    img {
      max-width: 100%;
      margin-top: 20px;
    }
  }
}
