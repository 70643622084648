/**
 * @see https://jira.vvit.co.uk/browse/VIT-7000
 * @see https://confluence.vvit.co.uk/display/DEV/Web+Guidelines+2020#WebGuidelines2020-ColourPalette
 */

/**
 * Colours from the VesselsValue brand guidelines
 * @see https://confluence.vvit.co.uk/display/DEV/Web+Guidelines+2020#WebGuidelines2020-ColourPaletteBrand
 */
$vv-light-red : #FAE8E8;
$vv-off-red : #FAC5C5;
$vv-red : #CC0000;
$vv-dark-red : #CC0000;
$vv-light-mid-red : #EB1E2D;
$gradient-red-end: #F41C1C;
$gradient-red-start: #B50000;
$vv-darkest-red : #8C1915;
$vv-light-teal : #6EC4C2;
$vv-dark-teal : #21A3A3;
$vv-light-yellow : #FAD7A0;
$vv-dark-yellow : #F2AE22;
$vv-orange : #EF5D24;
$vv-pink : #E62882;
$vv-dark-pink : #AA1E55;
$vv-seasure-blue : #0053A1;

/**
 * All permutations of grey currently used on the site
 * @see https://confluence.vvit.co.uk/display/DEV/Web+Guidelines+2020#WebGuidelines2020-ColourPaletteGreys
 */
$white : #FFFFFF;
$lighter-grey : #F7F7F7;
$vv-light-grey : #F3F4F5;
$lightest-mid-grey : #EEEEEE;
$lighter-mid-grey : #E5E5E5;
$light-mid-grey : #D9D9D9;
$mid-grey : #D7D7D7;
$dark-mid-grey : #D3D3D3;
$darker-mid-grey : #C2C2C2;
$vv-darkest-mid-grey : #C7C7C7;
$lightest-dark-grey : #BBBBBB;
$dimmed-grey : #999999;
$lighter-dark-grey : #898A84;
$vv-light-dark-grey : #6D6D6D;
$vv-dark-grey : #474747;
$darker-grey : #333333;
$darkest-grey : #2C2C2C;
$black : #000000;

$missing: #282828;

/**
 * Colour used for notifications on the website
 * @see https://confluence.vvit.co.uk/display/DEV/Web+Guidelines+2020#WebGuidelines2020-ColourPaletteNotifications
 */
$warning-alert-light-yellow : #FFF3CD;
$warning-alert-mid-yellow : #FFEEBA;
$warning-alert-dark-yellow : #856404;
$error-alert-light-pink : #F8D7DA;
$error-alert-mid-pink : #F5C6CB;
$error-alert-dark-pink : #721C24;
$success-alert-light-green : #D4EDDA;
$success-alert-mid-green : #C3E6CB;
$success-alert-dark-green : #155724;
$info-alert-light-blue : #D1ECF1;
$info-alert-mid-blue : #BEE5EB;
$info-alert-dark-blue : #0C5460;
$error-light-red : #FFCCCC;
$error-mid-red : #be2626;
$error-dark-red : #A51E22;
$notification-dark-green : #537155;
$light-pink : #FFBCBE;
$success-light-green : #CCFFCC;

/**
 * Valuation-specific colours
 * @see https://confluence.vvit.co.uk/display/DEV/Web+Guidelines+2020#WebGuidelines2020-ColourPaletteValuations
 */
$market-grey : #333333;
$demo-green : #1CAD1A;
$dcf-blue : #1A1CAD;
$replacement-purple : #800080;
$book-orange : #FF8040;
$fixed-age-purple : #5000B0;
$sales-pink : #E837CD;

/**
 * Misc.
 * @see https://confluence.vvit.co.uk/display/DEV/Web+Guidelines+2020#WebGuidelines2020-ColourPaletteOther
 */
$research-purple : #500D34;
$wechat-light-red : #D90F0F;
$wechat-dark-yellow : #E5A721;
$highlighted-light-blue : #CCFFFF;
$future-light-yellow : #FFFFCC;
$historical-light-pink : #FFCCFF;
$positive-green : #00AA00;
$negative-red : #8C0000;
$mint-green : #33FF99;
$lightest-pink : #FFE3E5;
$darker-light-pink : #D28F91;
$darkest-light-pink : #B87577;
$lightest-pale-yellow : #FFFFE0;
$lighter-pale-yellow : #FFFAE5;
$light-pale-yellow : #FAFADB;
$light-cream-yellow : #FFF6DD;
$dark-grey-yellow : #EEE5CC;
$bright-yellow : #FAFA03;
$mid-dark-yellow : #EFEF17;
$darker-yellow : #E6E60E;
$darkest-yellow : #E1E100;
$idwal-primary : #2D2859;
$bb-sols-primary : #F16822;
$linkedin-blue: #3182ce;

/// Retrieve a hex code from the colour map by key name
/// @access public
/// @param {String} $name
/// @return {Color}
/// @require $COLOUR_MAP
/// @throw 'Invalid colour name: #{$name}}'
@function colour ($name) {
  @if (map-has-key($COLOUR_MAP, $name)) {
    @return map-get($COLOUR_MAP, $name);
  }

  @if (type-of($name)==color) {
    @debug checkColourByHex($name);

    @return $name;
  }

  @error 'Invalid colour name: #{$name}';
}

/// Check that a given hex code exists within the colour map
/// @access public
/// @param {Color} $hex
/// @return {String}
@function checkColourExists ($hex) {
  @debug checkColourByHex($hex);

  @return 0;
}

/// Colours are to be migrated into this map and used with the colour() function in time
/// @access private
$COLOUR_MAP: ();

/// @access private
/// @param {Color} $hex-code
/// @return {String}
/// @require $COLOUR_MAP
@function checkColourByHex ($hex-code) {
  $colour-keys: map-keys($COLOUR_MAP);
  $colour-values: map-values($COLOUR_MAP);

  $index: index($colour-values, $hex-code);

  @if ($index) {
    @return 'Hex code #{$hex-code} found. Use "colour(#{nth($colour-keys, $index)})"';
  }

  @return 'Hex code #{$hex-code} not found';
}
