ul {
  margin: 0;
  padding: 0;
  list-style: none outside none;
  &.real_list {
    list-style: disc inside none;
    margin: 5px 10px;
  }
  &.square {
    list-style-type: square;
  }
  li {
    margin: 0;
    padding: 0;
    &.no_square {
      list-style-type: none;
    }
  }
}
.no_dot li {
  list-style: none;
}