@import '../abstracts/colors';

/* Notifications */
.alert {
  background-color: $white;
  border: 2px solid $vv-darkest-mid-grey;
  font-size: 1.333em;
  font-weight: normal;
  margin: 20px auto;
  padding: .75rem 1.25rem;

  &.alert-secondary {
    color: $darker-grey;
    background-color: $lighter-mid-grey;
    border-color: $light-mid-grey;
  }

  &.alert-success {
    color: $success-alert-dark-green;
    background-color: $success-alert-light-green;
    border-color: $success-alert-mid-green;
  }

  &.alert-warning {
    color: $warning-alert-dark-yellow;
    background-color: $warning-alert-light-yellow;
    border-color: $warning-alert-mid-yellow;
  }

  &.alert-fail {
    color: $error-alert-dark-pink;
    background-color: $error-alert-light-pink;
    border-color: $error-alert-mid-pink;
  }

  &.alert-info {
    color: $info-alert-dark-blue;
    background-color: $info-alert-light-blue;
    border-color: $info-alert-mid-blue;
  }
}

.alert.small {
  font-size: 1em;
}
