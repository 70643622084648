@import '../abstracts/colors';
@import '../abstracts/mixins';

/* Valuation Promo Panel
 * Not been used for over 4 years but code is still present on the valuation page
 * Probably no longer required so split the css into this file to make it easier to find for removal
 */
.valuation_promo_aside {
  width: 350px;
  float: right;
  margin: 0 auto;
  .promo_head {
    background-color: $vv-seasure-blue;
    font-weight: bold;
    padding: 0 10px;
    font-size: 14px;
    color: $white;
    h2, p {
      margin: 5px 0 0 0;
    }
  }
  .success {
    background-color: $success-light-green;
    padding: 10px 20px;
  }
  .error {
    background-color: $light-pink;
    padding: 0 20px;
  }
  .error p, .success p {
    margin: 5px 0;
  }
  .promo_body {
    border: 3px solid $vv-seasure-blue;
    padding: 5px;
    font-size: 14px;
    p {
      margin: 0 0 1em;
    }
  }
  .register_form {
    .left {
      input {
        clear: right;
        max-width: 60%;
      }
      label {
        text-align: left;
      }
    }
    .checkboxes {
      input {
        margin-left: 100px;
        width: 15px;
        clear: left;
      }
      label {
        width: 170px;
      }
    }
  }
  @include tablet {
    .valuation_promo_aside {
      float: none;
      margin: 0 auto;
      clear: both;
    }
  }
}
