@import '../../abstracts/colors';

// jQuery UI autocomplete
.ui-autocomplete {
  width: 150px;
  background-color: $white;
  top: -2px;
  z-index: 9999;
  border: 1px solid $vv-darkest-mid-grey;
  border-top: none;
  max-height: 200px;
  overflow-y: auto; /* prevent horizontal scrollbar */
  overflow-x: hidden; /* add padding to account for vertical scrollbar */
  a {
    display: block;
    padding: 3px;
  }
  #ui-active-menuitem {
    background-color: $lightest-mid-grey;
  }
  li.ui-menu-item {
    color: $vv-dark-red;
    background-color: transparent;
    text-decoration: none;
    cursor: pointer;
    padding: 3px 5px;
  }
  li {
    &.ui-menu-item > span {
      color: $lighter-dark-grey;
      font-size: 11px;
      float: right;
    }
    &.ui-autocomplete-category {
      cursor: default;
      font-weight: bold;
      padding: 3px 5px;
      user-select: none;
      -moz-user-select: none;
    }
  }
}

.ui-effects-transfer {
  background-color: $white;
  z-index: 9999;
  border-radius: 6px;
}
.ui-autocomplete-container {
  position: relative;
}
.ui-autocomplete-loading {
  background: $lightest-pink url("../img/ajax-loader.gif") no-repeat scroll 98% center;
}
// Custom overrides
#polyalert_autocomplete .ui-widget-content .ui-widget-content {
  border: 1px solid $vv-darkest-mid-grey;
  background-color: $lightest-pale-yellow !important;
  font-weight: bold;
}

.autocomplete-results {
  padding-right: 2px;

  ul.ui-autocomplete {
    /**
      This prevents a visual bug with the autocomplete input
      increasing in horizontal size upon loading results
    */
    max-width: 100% !important;

    li {
      color: $vv-dark-red;
      background-color: transparent;
      text-decoration: none;
      font-size: 1em;
      text-align: left;
      padding: 7px;
      font-weight: normal;
      cursor: pointer;
    }
  }
}
