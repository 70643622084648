@import '../abstracts/colors';

[class^="new-tag"] {
  color: $white;
  font-size: 0.5em;
  padding: 2px 7px;
  position: relative;
  bottom: 3px;
  border-radius: 3px;
}
.new-tag {
  &-red {
    background-color: $vv-red;
  }
  &-dark-red {
    background-color: $vv-dark-red;
  }
  &-blue {
    background-color: $vv-dark-teal;
  }
}
