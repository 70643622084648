@import '../../abstracts/colors';
@import '../../abstracts/mixins';

// Bootstrap Carousel Plugin
.carousel {
  position: relative;
}

.carousel-inner {
  min-height: 335px;
  position: relative;
  overflow: hidden;
  width: 100%;

  > .item {
    display: none;
    position: relative;
    -webkit-transition: 0.6s ease-in-out left;
    -o-transition: 0.6s ease-in-out left;
    transition: 0.6s ease-in-out left;

    > img,
    > a > img {
      line-height: 1;
    }

    @media all and (transform-3d), (-webkit-transform-3d) {
      -webkit-transition: -webkit-transform 0.6s ease-in-out;
      -o-transition: -o-transform 0.6s ease-in-out;
      transition: transform 0.6s ease-in-out;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      -webkit-perspective: 1000px;
      perspective: 1000px;
      &.next,
      &.active.right {
        -webkit-transform: translate3d(100%, 0, 0);
        transform: translate3d(100%, 0, 0);
        left: 0;
      }
      &.prev,
      &.active.left {
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0);
        left: 0;
      }
      &.next.left,
      &.prev.right,
      &.active {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        left: 0;
      }
    }
  }

  > .active,
  > .next,
  > .prev {
    display: block;
  }

  > .active {
    left: 0;

    &.left {
      left: -100%;
    }

    &.right {
      left: 100%;
    }
  }

  > .next,
  > .prev {
    position: absolute;
    top: 0;
    width: 100%;
  }

  > .next {
    left: 100%;
  }

  > .prev {
    left: -100%;
  }

  > .next.left,
  > .prev.right {
    left: 0;
  }

  #carousel-testimonial & {
    @include tablet-min {
      min-height: 375px;
    }
    @include sm-desktop-min {
      min-height: 338px;
    }
    @include desktop-min {
      min-height: 300px;
    }
  }
}

.carousel-control {
  position: absolute;
  top: 55%;
  transform: translateY(-55%);
  left: 0;
  bottom: 0;
  width: 15%;
  opacity: 0.5;
  font-size: 20px;
  color: $white;
  text-align: center;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
}

.carousel-control {
  #carousel-head &,
  #carousel-team & {
    width: 5%;
    @media screen and (min-width: 1024px) {
      width: 10%;
    }
    @include sm-tablet {
      width: 15%;
    }
  }

  &.left {
    left: 0;
  }

  &.right {
    left: auto;
    right: 0;
  }

  &:hover,
  &:focus {
    outline: 0;
    color: $white;
    text-decoration: none;
    opacity: 0.9;
    filter: alpha(opacity=90);
  }

  .icon-prev,
  .icon-next,
  img,
  svg {
    position: absolute;
    top: 30%;
    margin-top: -10px;
    z-index: 5;
    display: inline-block;
  }

  .icon-prev,
  .glyphicon-chevron-left {
    left: 0;
    margin-left: -10px;
  }

  .icon-next,
  .glyphicon-chevron-right {
    right: 0;
    margin-right: -10px;
  }

  .icon-prev {
    left: 0;
    @media screen and (min-width: 350px) {
      left: 10px;
    }

    #carousel-photos & {
      left: 30px;
    }
  }

  .icon-next {
    right: 0;
    @media screen and (min-width: 350px) {
      right: 10px;
    }

    #carousel-photos & {
      right: 30px;
    }
  }

  @include tablet-min {
    .glyphicon-chevron-left,
    .glyphicon-chevron-right,
    .icon-prev,
    .icon-next {
      margin-top: -15px;
      font-size: 30px;
    }

    .glyphicon-chevron-left,
    .icon-prev {
      margin-left: -15px;
    }

    .glyphicon-chevron-right,
    .icon-next {
      margin-right: -15px;
    }
  }
}

.carousel-indicators {
  position: absolute;
  bottom: 0;
  left: 50%;
  z-index: 15;
  width: 60%;
  margin-left: -30%;
  padding-left: 0;
  list-style: none;
  text-align: center;

  li {
    display: inline-block;
    width: 10px;
    height: 10px;
    margin: 1px;
    text-indent: -999px;
    border: 1px solid $white;
    border-radius: 10px;
    cursor: pointer;
    background-color: transparent;
  }

  .active {
    margin: 0;
    width: 12px;
    height: 12px;
    background-color: $white;
  }

  @include tablet-min {
    bottom: -20px;
  }
}

.carousel-caption {
  position: absolute;
  left: 15%;
  right: 15%;
  bottom: 20px;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: $white;
  text-align: center;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);

  .btn {
    text-shadow: none;
  }

  @include tablet-min {
    left: 20%;
    right: 20%;
    padding-bottom: 30px;
  }
}

.carousel-content {
  padding: 40px 0 80px 0;
}

/* Custom Overrides */
#carousel-team-title-container,
#carousel-testimonial .carousel-content {
  text-align: center;
}

#carousel-photos .sy-dialog-photo {
  width: auto;
  max-width: 1024px;
  min-height: auto;
  max-height: calc(100vh / (16 / 9));;
}

@include sm-tablet {
  #carousel-head .carousel-inner {
    max-height: none;
  }
  .carousel-indicators {
    display: none;
  }
}

.carousel.testimonals {
  .quote_marks {
    display: block;
    margin: 30px auto 0;
  }

  p {
    line-height: 2em;
    font-size: 1.33em;
  }
}

@include mobile {
  #carousel-testimonial .carousel-inner p {
    font-size: 1em;
  }
}

.carousel-control {
  &.left svg {
    width: 25px;
    height: 25px;
  }

  &.right svg {
    width: 25px;
    height: 25px;
  }
}
