@import "../abstracts/colors";
@import "../abstracts/mixins";

.pagination-container {
  user-select: none;
  text-align: right;

  a, select {
    border: 1px solid $lightest-mid-grey;
    border-radius: 3px;
    padding: 3px 5px;

    &:hover {
      border-color: $vv-red;
    }

    &[data-target] {
      font-size: 15px;
      padding: 0 5px 3px 5px;
    }
  }

  a {
    display: inline-block;
    text-align: center;

    &[data-page-active="true"] {
      background: $vv-dark-red;
      border: 1px solid $vv-dark-red;
      color: $white;
    }
  }

  select {
    background: transparent;
    cursor: pointer;
    margin: 2px;

    option {
      color: $black;
    }
  }
}

.pagination_container {
  margin: 20px 0 0 5px;
  clear: both;

  @include mobile {
    margin-top: 10px;

    h2 {
      position: relative !important;
    }
  }
}

.pagination_bottom {
  margin: -20px 0 20px 5px;
}

.pagination, .vv_pagination {
  margin-left: 5px;
  float: right;

  a {
    &:first-of-type {
      @apply border-l;
      @apply rounded-l;
    }
    &:last-of-type {
      @apply border-r;
      @apply rounded-r;
    }
  }

  a:hover {
    color: $black;
  }

  span,
  a,
  a:link,
  a:visited {
    background: none repeat scroll 0 0 transparent;
    display: block;
    float: left;
    text-decoration: none;
    cursor: pointer;
  }


  li {
    border: 0 none;
    float: left;
    list-style: none outside none;

    &.next a,
    &.previous a {
      background: none repeat scroll 0 0 transparent;
      border: medium none;
      padding: 2px 5px;
    }

    &.dots {
      color: #B6B8BB;
      padding-right: 3px;
    }
  }

  .active {
    background: none repeat scroll 0 0 #9DA1A7;
    border-color: #9DA1A7 !important;
    color: #FFFFFF;
    display: block;
    float: left;
    margin-right: 3px;
    padding: 2px 4px;
  }

  .divider {
    display: block;
    float: left;
    padding: 4px 6px 0 4px;
  }

  .faded {
    color: #B6B8BB;
    border-color: #D7D7D7 !important;
    cursor: default;
  }

  .limiter {
    border: 0;

    select {
      float: none;
      min-width: 80px;
    }
  }

  .total {
    border: 0 none;
    padding: 2px 6px;
  }
}

@include mobile {
  .pagination .limiter {
    display: block;
    padding: 0;
  }
}
