@import '../abstracts/functions';
@import '../abstracts/mixins';

$sizes: (auto, 0, 1px, 2px, 3px, 4px, 5px, 8px, 9px, 10px, 12px, 15px, 20px, 25px, 26px, 30px, 35px, 40px, 45px, 50px, 60px, 70px, 80px, 90px, 100px, 120px, 150px, 200px, 250px, 20%, 40%, -50px, -100px); // Adjust this to include the pixel amounts needed, be careful when deleting, check the class isn't in use
$sides: (top, bottom, left, right, x, y); // DO NOT EDIT

//  Padding Mixin
//  @param $side string "x", "y", string - equates to left, right, or specific. @See $sides
//  @param $size string "23px, "1.3em" - the size in a string.
@mixin padding($side, $size) {
  @if (equals($side, x)) {
    padding-left: #{$size};
    padding-right: #{$size};
  } @else if (equals($side, y)) {
    padding-top: #{$size};
    padding-bottom: #{$size};
  } @else {
    padding-#{$side}: #{$size};
  }
}

@each $size in $sizes {
  $size-class: #{convert-size-to-class($size)};
  .vv-p-#{$size-class} {
    padding: #{$size};
  }
}

@each $size in $sizes {
  $size-class: #{convert-size-to-class($size)};
  @each $side in $sides {
    $side-class: str-slice($side, 0, 1);
    .vv-p#{$side-class}-#{$size-class} {
      @include padding($side, $size);
    }
  }
}

@include desktop {
  @each $size in $sizes {
    $size-class: #{convert-size-to-class($size)};
    .vv-xl-p-#{$size-class} {
      padding: #{$size};
    }
    @each $side in $sides {
      $side-class: str-slice($side, 0, 1);
      .vv-xl-p#{$side-class}-#{$size-class} {
        @include padding($side, $size);
      }
    }
  }
}

@include sm-desktop {
  @each $size in $sizes {
    $size-class: #{convert-size-to-class($size)};
    .vv-lg-p-#{$size-class} {
      padding: #{$size};
    }
    @each $side in $sides {
      $side-class: str-slice($side, 0, 1);
      .vv-lg-p#{$side-class}-#{$size-class} {
        @include padding($side, $size);
      }
    }
  }
}

@include tablet {
  @each $size in $sizes {
    $size-class: #{convert-size-to-class($size)};
    .vv-md-p-#{$size-class} {
      padding: #{$size};
    }
    @each $side in $sides {
      $side-class: str-slice($side, 0, 1);
      .vv-md-p#{$side-class}-#{$size-class} {
        @include padding($side, $size);
      }
    }
  }
}

@include sm-tablet {
  @each $size in $sizes {
    $size-class: #{convert-size-to-class($size)};
    .vv-sm-p-#{$size-class} {
      padding: #{$size};
    }
    @each $side in $sides {
      $side-class: str-slice($side, 0, 1);
      .vv-sm-p#{$side-class}-#{$size-class} {
        @include padding($side, $size);
      }
    }
  }
}

@include mobile {
  @each $size in $sizes {
    $size-class: #{convert-size-to-class($size)};
    .vv-xs-p-#{$size-class} {
      padding: #{$size};
    }
    @each $side in $sides {
      $side-class: str-slice($side, 0, 1);
      .vv-xs-p#{$side-class}-#{$size-class} {
        @include padding($side, $size);
      }
    }
  }
}

@include sm-mobile {
  @each $size in $sizes {
    $size-class: #{convert-size-to-class($size)};
    .vv-xxs-p-#{$size-class} {
      padding: #{$size};
    }
    @each $side in $sides {
      $side-class: str-slice($side, 0, 1);
      .vv-xxs-p#{$side-class}-#{$size-class} {
        @include padding($side, $size);
      }
    }
  }
}
