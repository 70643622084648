@import '../abstracts/mixins';

.at {
  vertical-align: top;
}

.am {
  vertical-align: middle;
}

.ab {
  vertical-align: bottom;
}

@each $val in (top, middle, bottom, sub, text-top) {
  .v-align-#{$val} {
    vertical-align: $val;
  }
}

@mixin alignItems($prefix: null) {
  @if(is-not-null($prefix)) {
    $prefix: #{$prefix}-
  }

  .#{$prefix}items-stretch {
    align-items: stretch;
  }

  .#{$prefix}items-start {
    align-items: flex-start;
  }

  .#{$prefix}items-center {
    align-items: center;
  }

  .#{$prefix}items-end {
    align-items: flex-end;
  }

  .#{$prefix}items-baseline {
    align-items: baseline;
  }
}

@include alignItems(null);

@include mobile {
  @include alignItems('sm');
}

@include tablet {
  @include alignItems('md');
}

@include desktop {
  @include alignItems('lg');
}
