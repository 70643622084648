@import '../abstracts/colors';
@import '../abstracts/mixins';

// helper class available @include seasure();
.seasure, .seasure_only, a.seasure_only, .dcf_seasure_only.loan-calculator {
  color: $vv-seasure-blue;
}

.seasure:after, .seasure_bg {
  background-color: $vv-seasure-blue;
}

/* Seasure Only Button */
button, .button,
form input[type=submit],
form input[type=reset],
form input[type=button] {
  &.seasure_only {
    background-color: $vv-seasure-blue;
    border-color: $vv-seasure-blue;
    color: $white;

    &:hover, &:active {
      background-color: $white;
      border-color: $vv-seasure-blue;
      color: $vv-seasure-blue;
    }
  }
}

// Seasure only legacy checkbox
.default14, {
  [type="checkbox"] {
    &:disabled {
      &:not(:checked),
      &:checked {
        + label.seasure_only {
          &:before {
            border-color: $dimmed-grey;
          }
        }
      }

      &:checked {
        + label.seasure_only {
          &:after {
            color: $dimmed-grey;
          }
        }
      }
    }

    &:not(:checked),
    &:checked {
        + label.seasure_only {

          &:before {
            border-color: $vv-seasure-blue;
          }

          &:after {
            color: $vv-seasure-blue;
          }
      }
    }
  }
}

@include mobile {
  .debug_database_queries {
    display: none;
  }
}

#seasure_only_button {
  position: absolute;
  left: 5px;
  top: 0;
  width: 10px;
  height: 10px;
  text-align: center;
  display: block;
  color: $white;
  padding: 5px;
  border: 1px dotted $vv-darkest-mid-grey;
  border-top: 0;
  background-color: $white;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;

  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 5px;
    left: 2px;
    width: 0;
    height: 0;
    border-top: 8px solid $vv-seasure-blue;
    border-right: 8px solid transparent;
    border-bottom: 0 solid transparent;
    border-left: 8px solid transparent;
  }

  &.open:after,
  &.open:before {
    transform: rotate(45deg);
    position: absolute;
    border: 0;
    top: 2px;
    left: 9px;
    content: ' ';
    height: 15px;
    width: 3px;
    background-color: $vv-seasure-blue;
  }

  &.open:before {
    transform: rotate(-45deg);
  }
}

.logs-box-head.seasure_only {
  background-color: $vv-seasure-blue;
}

/*Seasure Control Bar */
.admin_con {
  background: $lightest-mid-grey;
  vertical-align: center;
}
