@import '../abstracts/mixins';

$sizes: (auto, 0, 1px, 2px, 3px, 5px, 8px, 10px, 12px, 14px, 15px, 16px, 18px, 20px, 25px, 30px, 35px, 40px, 45px, 50px, 60px, 70px, 80px, 100px, 150px, 10%, 20%, 30%, 33%, 40%, 50%, -5px, -10px, -20px, -50px, -100px); // Adjust this to include the pixel amounts needed, be careful when deleting, check the class isn't in use
$sides: (top, bottom, left, right, x, y); // DO NOT EDIT

@each $size in $sizes {
  $size-class: #{convert-size-to-class($size)};

  .vv-m-#{$size-class} {
    margin: #{$size};
  }
}

@each $size in $sizes {
  $size-class: #{convert-size-to-class($size)};

  @each $side in $sides {
    $side-class: str-slice($side, 0, 1);

    .vv-m#{$side-class}-#{$size-class} {
      @if (equals($side, x)) {
        margin-left: #{$size};
        margin-right: #{$size};
      }

      @else if (equals($side, y)) {
        margin-top: #{$size};
        margin-bottom: #{$size};
      }

      @else {
        margin-#{$side}: #{$size};
      }
    }
  }
}

@each $size in $sizes {
  $size-class: #{convert-size-to-class($size)};

  @each $side in $sides {
    $side-class: str-slice($side, 0, 1);

    @include desktop {
      .vv-xl-m#{$side-class}-#{$size-class} {
        @if (equals($side, x)) {
          margin-left: #{$size};
          margin-right: #{$size};
        }

        @else if (equals($side, y)) {
          margin-top: #{$size};
          margin-bottom: #{$size};
        }

        @else {
          margin-#{$side}: #{$size};
        }
      }
    }
  }
}

@each $size in $sizes {
  $size-class: #{convert-size-to-class($size)};

  @each $side in $sides {
    $side-class: str-slice($side, 0, 1);

    @include sm-desktop {
      .vv-lg-m#{$side-class}-#{$size-class} {
        @if (equals($side, x)) {
          margin-left: #{$size};
          margin-right: #{$size};
        }

        @else if (equals($side, y)) {
          margin-top: #{$size};
          margin-bottom: #{$size};
        }

        @else {
          margin-#{$side}: #{$size};
        }
      }
    }
  }
}

@each $size in $sizes {
  $size-class: #{convert-size-to-class($size)};

  @each $side in $sides {
    $side-class: str-slice($side, 0, 1);

    @include tablet {
      .vv-md-m#{$side-class}-#{$size-class} {
        @if (equals($side, x)) {
          margin-left: #{$size};
          margin-right: #{$size};
        }

        @else if (equals($side, y)) {
          margin-top: #{$size};
          margin-bottom: #{$size};
        }

        @else {
          margin-#{$side}: #{$size};
        }
      }
    }
  }
}

@each $size in $sizes {
  $size-class: #{convert-size-to-class($size)};

  @each $side in $sides {
    $side-class: str-slice($side, 0, 1);

    @include sm-tablet {
      .vv-sm-m#{$side-class}-#{$size-class} {
        @if (equals($side, x)) {
          margin-left: #{$size};
          margin-right: #{$size};
        }

        @else if (equals($side, y)) {
          margin-top: #{$size};
          margin-bottom: #{$size};
        }

        @else {
          margin-#{$side}: #{$size};
        }
      }
    }
  }
}

@each $size in $sizes {
  $size-class: #{convert-size-to-class($size)};

  @each $side in $sides {
    $side-class: str-slice($side, 0, 1);

    @include mobile {
      .vv-xs-m#{$side-class}-#{$size-class} {
        @if (equals($side, x)) {
          margin-left: #{$size};
          margin-right: #{$size};
        }

        @else if (equals($side, y)) {
          margin-top: #{$size};
          margin-bottom: #{$size};
        }

        @else {
          margin-#{$side}: #{$size};
        }
      }
    }
  }
}

@each $size in $sizes {
  $size-class: #{convert-size-to-class($size)};

  @each $side in $sides {
    $side-class: str-slice($side, 0, 1);

    @include sm-mobile {
      .vv-xxs-m#{$side-class}-#{$size-class} {
        @if (equals($side, x)) {
          margin-left: #{$size};
          margin-right: #{$size};
        }

        @else if (equals($side, y)) {
          margin-top: #{$size};
          margin-bottom: #{$size};
        }

        @else {
          margin-#{$side}: #{$size};
        }
      }
    }
  }
}

.first\:ml-0 {
  > *:first-child {
    margin-left: 0;
  }
}

.last\:mr-0 {
  > *:last-child {
    margin-right: 0;
  }
}
