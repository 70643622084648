@import '../abstracts/colors';
@import '../abstracts/mixins';

/* Notes */
.note_new {
  margin-top: 12px;
  .note_text {
    vertical-align: text-bottom;
    white-space: normal;
    overflow: visible;
  }
  .note_buttons {
    margin-left: 10px;
    .note_add {
      float: left;
    }
    @include mobile {
      margin: 0;
    }
  }
}
#notes_results {
  .note_text {
    width: auto;
    height: 100%;
    padding: 10px;
    &.scroll-x {
      overflow-x: auto;
    }
    .button {
      float: right;
      margin: 0 0 6px 3px;
    }
  }
  .note_full {
    display: none;
    background-color: $lighter-mid-grey;
  }
}
.note_text {
  textarea {
    box-sizing: border-box;
    resize: vertical;
    overflow: auto;
    width: 100%;
    min-height: 30px;
  }
}
