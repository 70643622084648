@import '../abstracts/colors';
@import '../abstracts/mixins';

table {
  /**
   * A short-term solution to resolve issues
   * that border-box has caused for some of
   * the more complex tables
   */
  &.table-header-content-box {
    thead {
      th {
        box-sizing: content-box;
      }
    }
  }

  border-collapse: collapse;
  text-align: left;
}

.tlf {
  table-layout: fixed;
}

.tableHeader {
  width: 100%;
  margin: 10px 0 0 0;
  table-layout: fixed;
  font-size: 0.75rem;

  &.cell_wrap td,
  &.cell_wrap th {
    white-space: normal;
  }

  tr {
    height: 30px;
  }

  thead {
    tr {
      background-color: $lighter-mid-grey;

      th {
        background: $lighter-mid-grey repeat-x center;
        border-bottom: 1px solid $dark-mid-grey;
        border-top: 1px solid $lighter-mid-grey;
        color: $black;
        padding: 0 6px;
        vertical-align: middle;

        &.bdbn {
          border-bottom: none;
        }

        &.bdtn {
          border-top: none;
        }

        @include seasure();
      }

      &.sorting_top th {
        cursor: pointer;

        &:hover a:not(.more_info) {
          text-decoration: underline;
        }

        &.blank {
          cursor: default;
        }

        &.sortAsc, &.sortDesc {
          position: relative;
          background: -webkit-gradient(linear, left top, left bottom, from($vv-darkest-mid-grey), to(rgba(245, 245, 245, 0)));
          background: linear-gradient(to bottom, $vv-darkest-mid-grey 0%, rgba(245, 245, 245, 0) 100%);
        }

        &.sortDesc::before {
          content: '';
          width: 0;
          height: 0;
          border-left: 6px solid transparent;
          border-right: 6px solid transparent;
          border-top: 6px solid $vv-light-dark-grey;
          position: absolute;
          top: 0;
          left: calc(50% - 3px);
        }

        &.sortAsc::before {
          content: '';
          width: 0;
          height: 0;
          border-left: 6px solid transparent;
          border-right: 6px solid transparent;
          border-bottom: 6px solid $vv-light-dark-grey;
          position: absolute;
          top: 0;
          left: calc(50% - 3px);
        }

        &.mini_sort {
          background-position: left;
        }

        &.noSort {
          cursor: default;
        }
      }
    }
  }

  td,
  th {
    &.sorted {
      background-color: $vv-light-grey;
    }

    &.action {
      background-color: $darker-yellow;
      border-bottom-color: $darkest-yellow;
      text-align: center;
    }

    &.headerLeftWithDropDown, &.Right {
      border-left: 1px solid $lighter-mid-grey;
    }

    &.headerLeft {
      border-left: 1px solid $vv-darkest-mid-grey;
      padding-left: 11px;
    }

    &.headerRight {
      border-right: 1px solid $vv-darkest-mid-grey;
    }
  }

  tbody {
    tr {
      &:hover {
        background-color: $lightest-pale-yellow;

        td,
        th {
          &.sorted {
            background-color: $light-pale-yellow;
          }

          &.action {
            background-color: $bright-yellow;
          }
        }
      }

      &.open {
        &:hover {
          background-color: $light-mid-grey;

          td.sorted,
          th.sorted {
            background-color: $dark-mid-grey;
          }
        }

        td.sorted,
        th.sorted {
          background-color: $dark-mid-grey;
        }
      }

      &.odd {
        background-color: $lighter-grey;

        &:hover {
          background-color: $lightest-pale-yellow;

          td.sorted,
          th.sorted {
            background-color: $light-pale-yellow;
          }
        }

        td.sorted,
        th.sorted {
          background-color: $lightest-mid-grey;
        }

        td.action,
        th.action {
          background-color: $mid-dark-yellow;
        }
      }

      &.v_odd {
        background-color: $lighter-grey;
      }

      &.noHighlight {
        th {
          white-space: nowrap;
        }

        td {
          border: medium none;
        }
      }

      &.show-row {
        display: table-row;
      }
    }
  }

  td {
    border: 0 solid $lighter-mid-grey;
    border-bottom-width: 1px;
    padding: 0 6px;
    line-height: 20px;
    height: 20px;
    vertical-align: middle;
    overflow: hidden;
    white-space: nowrap;

    .borderLeft {
      border-left: 1px solid $lighter-mid-grey;
    }

    td.nowrap {
      white-space: normal;
    }

    &.red {
      border-bottom: 1px solid $lighter-mid-grey !important;
      cursor: pointer;
    }

    &.cell_wrap {
      vertical-align: top;
      white-space: normal;
    }

    &.sorted {
      border-bottom-color: $lighter-mid-grey;
    }
  }

  .twoline {
    th,
    td,
    tr {
      height: 15px;
    }
  }

  .halftop {
    border-bottom: 0 none;
  }

  .halfbot {
    border-top: 0 none;
    background-position-y: -15px;
  }

  .profit_graph_row {
    td {
      line-height: inherit;
      height: auto;
    }

    .chart_container {
      height: 400px;
    }
  }

  .subhead {
    height: 18px;

    th {
      font-weight: normal;
    }
  }

  #potfolio_results_footer {
    /* background: url("../img/table_head.png") repeat-x scroll left top transparent !important; */
    background-color: $lighter-mid-grey;
    border-bottom: 1px solid $dark-mid-grey !important;
    border-top: 5px solid $lighter-mid-grey !important;
    color: $vv-light-dark-grey;
    padding: 8px 6px 9px;
    text-shadow: 1px 1px 0 $white;
  }

  .add {
    color: $vv-dark-red;
  }

  tfoot.custom_value_totals {
    th {
      border-bottom: 2px solid $black !important;
      border-top: 2px solid $black !important;
      background: transparent;
    }

    td {
      border-bottom: 0 none;
      font-weight: bold;
    }
  }

  div {
    height: 20px;
    overflow: hidden;
  }
}

table.tableTabsHeader tr.noHighlight td {
  border: medium none;
}

/* Highcharts Table specific styling*/
.highcharts-data-table table {
  width: 100%;

  th, td {
    text-align: right;
  }

  th {
    background: $lighter-mid-grey repeat-x center;
    border-bottom: 1px solid $dark-mid-grey;
    border-top: 1px solid $lighter-mid-grey;
    color: $black;
    padding: 0 6px;
  }

  tr {
    height: 30px;
  }
}

/* Used on Market Valuation page for Demo Value table on Demo tab */
.vesseldetails {
  table {
    margin-top: 4px;

    th,
    td {
      text-align: left;
      padding: 4px;
      vertical-align: top;
    }

    th {
      font-weight: normal;
      color: $lighter-dark-grey;
    }

    .ui-datepicker th {
      text-align: center;
      padding: 0 6px;
      vertical-align: middle;
    }

    &.big {
      margin-top: 16px;

      tbody .headrow {
        td,
        th {
          height: 30px;
          background-color: $lighter-mid-grey;
          border-bottom: 1px solid $dark-mid-grey;
          border-top: 1px solid $lighter-mid-grey;
          color: $black;
          padding: 0;
          font-weight: bold;
          vertical-align: middle;
        }

        th {
          padding-left: 29px;

          a {
            text-shadow: none !important;
          }
        }
      }

      th {
        font-weight: normal;
        padding-left: 29px;
        vertical-align: middle;
      }

      td {
        font-weight: bold;
        text-align: right;
        padding-bottom: 0;
        vertical-align: middle;
        padding-right: 0;
      }

      .space {
        font-size: 1px;
        height: 16px;
        padding: 0;
      }

      .star {
        width: 22px;
        color: $black;
        margin: 0;
        float: none;
        padding-left: 0;
        text-align: left;
      }
    }
  }
}

/* Valuation page custom value tab */
.custom_value_tab {
  width: 100%;

  table {
    &.tableHeader {
      th,
      td {
        padding: 0 4px;

        &.valueEditable{
          text-align: left;
        }

        &.editable {
          padding-right: 28px !important;
        }

        &.linearEditable {
          padding-right: 14px !important;
        }

        &.eventDateEditable {
          text-align: left;
          padding-left: 12px !important;
        }
      }

      th {
        text-align: right;

        &.al {
          text-align: left;
        }
      }

      tfoot th {
        background: $lighter-mid-grey repeat-x center;
        border-bottom: 1px solid $dark-mid-grey;
        border-top: 1px solid $lighter-mid-grey;
        color: $black;
        padding: 0 6px;
        vertical-align: middle;
      }
    }

    .custom_value_input {
      margin-top: 0;
      float: right;

      input {
        text-align: right;

        &.hasDatepicker {
          text-align: left;
        }
      }
    }
  }
}

.custom_value_totals td {
  text-align: right;
}

#invoice_summary tfoot th {
  font-weight: normal;
  text-align: right;
  border-top: 1px solid $black;
  border-bottom: 2px solid $black;
}

.document_results thead th.sortable {
  color: $vv-dark-red;
  text-decoration: none;
}

table.db_explain {
  border-collapse: collapse;
  border: 1px solid $dimmed-grey;
  margin: 15px 0 5px 0;
  table-layout: fixed;

  th {
    border: 1px solid $dimmed-grey;
    text-align: center;

    &:nth-child(1),
    &:nth-child(9) {
      width: 40px;
    }

    &:nth-child(5),
    &:nth-child(11) {
      width: 150px;
    }

    &:nth-child(10) {
      width: 50px;
    }
  }

  td {
    border-left: 1px solid $dimmed-grey;
    border-right: 1px solid $dimmed-grey;
    vertical-align: top;
    word-wrap: break-word;
  }
}

table.about_features th, table.about_features td {
  padding: 8px;
  white-space: normal;
}

table.about_features .odd td {
  background-color: $lightest-mid-grey;
}

.striped-even tr:nth-child(even) {
  background-color: $lighter-grey;
}
