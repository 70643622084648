@import '../abstracts/colors';
@import '../abstracts/mixins';

button,
input,
select,
optgroup,
textarea {
  margin: 0;
  font: inherit;
  color: inherit;
  border: 1px solid $vv-darkest-mid-grey;
  padding: 3px 4px;
  background-color: $lighter-grey;
}

input,
optgroup,
select,
textarea {

  &.disabled,
  &.disabled:hover,
  &[disabled="disabled"],
  [disabled="disabled"]:hover {
    /* remove important once custom form styles have been migrated */
    background-color: $lighter-mid-grey !important;
    border-color: $dimmed-grey !important;
  }
}

input {
  line-height: normal;
  border-radius: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;

  &::-moz-focus-inner {
    padding: 0;
    border: 0;
  }

  &.loading_plus {
    background: url(../img/loadingAnimation.gif) 90% no-repeat;
    background-size: 20px;
  }
}

select {
  padding: 2px 4px;
  text-transform: none;
  box-sizing: border-box;
}

select.edrop {
  margin-right: 3px;
  max-width: 75%;
}

@include tablet {
  textarea {
    max-width: 100%;
  }
}

input[disabled] {
  cursor: default;
}

input[type="checkbox"],
input[type="radio"] {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
  cursor: pointer;
  margin: 3px 5px 3px 0;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

input[type="search"] {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  -webkit-appearance: none;

  &::-webkit-search-cancel-button,
  &::-webkit-search-decoration {
    -webkit-appearance: none;
  }
}

/** A note here moving forward, avoid targeting HTMLElements, it makes it a pain to override and important will start to creep it **/
form,
.form {
  width: auto;
  float: none;

  input {

    &[type=submit],
    &[type=reset],
    &[type=button] {
      &.black {
        border: 1px solid $black;
        color: $black;

        &:hover {
          color: $vv-red;
        }
      }

      /* White Button */
      &.white {

        /* White-Red Button */
        &-red {
          background-color: $white;
          color: $vv-light-mid-red;

          &:hover {
            background-color: $vv-dark-red;
            color: $white;
          }

          /* White-Red-Grey Button */
          &-grey {
            background-color: $white;
            color: $lighter-dark-grey;

            &:hover {
              background-color: $vv-dark-red;
              color: $white;
            }
          }
        }

        &:hover {
          background-color: $vv-dark-red;
          color: $white;
        }
      }

      /* Red Button */
      &.red {
        background-color: $vv-red;
        color: $white;
      }

      &.trans {
        background-color: transparent;

        &:hover {
          background-color: $white;
          color: $vv-red;
        }
      }

      &.wb {
        border: 1px solid $white;
      }

      &.nob {
        border: 0;
      }

      &.fw {
        width: 100%;
      }
    }
  }

  label {
    display: block;
    text-align: right;
    float: left;
    padding: 2px 0 0 0;

    &.button {
      display: inline;
      float: none;
    }

    &.error {
      color: $vv-red;
      float: left;
      font-size: 12px;
      margin: 0;
      padding: 0 2px 2px;
      position: relative;
      text-align: left;
    }
  }

  .form_element {
    &.inline-checkbox {
      height: 27px;
    }

    &.error {
      color: $vv-dark-red;
    }

    label {
      width: auto;
      float: none;
      text-align: inherit;

      &:not(.dn) {
        display: block;
      }

      padding: 10px 5px 10px 0;
    }

    input:not([type="submit"]),
    select {
      font-size: 1.2em;
      padding: 10px;
      border: 1px solid $vv-darkest-mid-grey;
      width: 100%;
      background-color: $lighter-grey;
      border-radius: 5px;

      &.lg_input {
        padding-top: 11px;
        padding-bottom: 11px;
      }
    }

    button[disabled],
    input[disabled],
    optgroup[disabled],
    select[disabled],
    textarea[disabled] {
      background-color: $lightest-mid-grey;
    }
  }

  .subscribe_heading {
    /* OLD style form headings */
    margin: 0 0 10px 0;
    background-color: $vv-red;
    color: $white;
    font-size: 14px;
    padding: 5px;
  }

  .tel1 {
    background: url("../img/plus.jpg") no-repeat scroll 7px center $white;
    margin-right: 4px;
    padding: 4px 4px 4px 20px;
    width: 48px;
    border: 1px solid $lighter-dark-grey;
  }

  .tel2 {
    width: 185px;
  }
}

.default14,
.legacy-checkbox {
  [type="checkbox"] {

    &:not(:checked),
    &:checked {
      visibility: hidden;
      position: absolute;

      +label {
        position: relative;
        padding-left: 40px;
        padding-top: 5px;
        cursor: pointer;
        min-height: 12px;

        &:before {
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          width: 25px;
          height: 25px;
          border: 1px solid $lighter-dark-grey;
          background: $white;
          border-radius: 4px;
        }

        &:after {
          font-family: "icomoon-vv";
          content: $vv-icon-check;
          speak: none;
          font-style: normal;
          font-weight: normal;
          font-variant: normal;
          text-transform: none;
          line-height: 1;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          position: absolute;
          left: 6px;
          top: 5px;
          color: $lighter-dark-grey;
          transition: all .2s;
        }
      }

      &:focus {
        +label:before {
          border: 1px dotted $darker-grey;
        }
      }
    }

    &:not(:checked) {
      +label:after {
        opacity: 0;
        transform: scale(0);
      }
    }

    &:checked {
      +label:after {
        opacity: 1;
        transform: scale(1);
      }
    }

    &.small+label {
      padding-left: 25px;
      padding-top: 1px;

      &:before {
        margin-top: 5px;
        width: 15px;
        height: 15px;
      }

      &:after {
        left: 1px;
        font-size: 1em;
      }
    }
  }

  :disabled {

    &:not(:checked),
    &:checked {
      +label {
        &:before {
          box-shadow: none;
          border-color: $dimmed-grey;
          background-color: $lighter-mid-grey;
          cursor: default;
        }
      }
    }

    &:checked {
      +label {
        &:after {
          color: $dimmed-grey;
        }
      }
    }
  }

  +label {
    color: $vv-darkest-mid-grey;
  }

  label:hover:before {
    border: 1px solid $darker-grey !important;
  }
}

.generic_error {
  color: $vv-dark-red;
}

/* will probably move this when bringing in the other styles for these sections */
.trade_page_content form p {
  font-size: inherit;
}

input[type="text"].text-input-large {
  font-size: 1.2em;
  padding: 10px;
  border: 1px solid $vv-darkest-mid-grey;
  width: 100%;
  -moz-box-shadow: inset 0 0 12px $lightest-mid-grey;
  -webkit-box-shadow: inset 0 0 12px $lightest-mid-grey;
  box-shadow: inset 0 0 12px $lightest-mid-grey;
  background-color: $lighter-grey;
}

.vv-input {
  font-size: 1rem;
  padding: 13px 15px;
  border: 1px solid $vv-darkest-mid-grey;
  width: 100%;
  box-shadow: inset 0 0 12px $lightest-mid-grey;
  background-color: $lighter-grey;
}


input.input-rounded-style,
input.hasDatepicker.datepicker-rounded-style,
textarea.textarea-rounded-style,
a.link-rounded-style {
  @apply rounded border border-grey-400 justify-center overflow-hidden px-2 bg-grey-100 text-sm;
}


select.select-rounded-style {
  @apply text-grey-800 outline-none border border-grey-400 text-sm -mx-1 mx-1 bg-grey-100 appearance-none rounded pl-2 pr-8 bg-no-repeat;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23999999'%3e%3cpath d='M15.3 9.3a1 1 0 0 1 1.4 1.4l-4 4a1 1 0 0 1-1.4 0l-4-4a1 1 0 0 1 1.4-1.4l3.3 3.29 3.3-3.3z'/%3e%3c/svg%3e");
  -webkit-appearance: none;
  -moz-appearance: none;
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
  background-position: right 0.5rem center;
  background-size: 1.5em 1.5em;


  &::-ms-expand{
    @apply border-none;
    color: #999999;
  }

  @media not print{
    &::-ms-expand{
      display: none;
    }
  }

  @media print and (-ms-high-contrast: active), print and (-ms-high-contrast: none){
    &{
      @apply pr-3;
    }
  }

  &:focus{
    @apply outline-none;
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);
    border-color: #63b3ed;
  }

  &:focus{
    @apply outline-none;
  }
}

@import "../components/forms/checkboxes";
@import "../components/forms/mulitselect";
@import "../components/forms/radios";
@import "../components/forms/switch";
