@import '../abstracts/mixins';

$grid-columns: (
  (class : 'one_twentieth', cols : 1, total : 20),
  (class : 'one_fourteenth', cols : 1, total : 14),
  (class : 'one_twelfth', cols : 1, total : 12),
  (class : 'one_eleventh', cols : 1, total : 11),
  (class : 'one_tenth', cols : 1, total : 10),
  (class : 'one_ninth', cols : 1, total : 9),
  (class : 'one_eighth', cols : 1, total : 8),
  (class : 'one_seventh', cols : 1, total : 7),
  (class : 'one_sixth', cols : 1, total : 6),
  (class : 'one_fifth', cols : 1, total : 5),
  (class : 'one_quarter', cols : 1, total : 4),
  (class : 'content_left', cols : 1, total : 4),
  (class : 'two_sevenths', cols : 2, total : 7),
  (class : 'three_tenths', cols : 3, total : 10),
  (class : 'one_third', cols : 1, total : 3),
  (class : 'content_third', cols : 1, total : 3),
  (class : 'true_third', cols : 1, total : 3),
  (class : 'two_fifths', cols : 2, total : 5),
  (class : 'four_elevenths', cols : 4, total : 11),
  (class : 'five_elevenths', cols : 5, total : 11),
  (class : 'six_elevenths', cols : 6, total : 11),
  (class : 'seven_elevenths', cols : 7, total : 11),
  (class : 'one_half', cols : 1, total : 2),
  (class : 'content_half', cols : 1, total : 2),
  (class : 'three_fifths', cols : 3, total : 5),
  (class : 'two_thirds', cols : 2, total : 3),
  (class : 'true_two_thirds', cols : 2, total : 3),
  (class : 'seven_tenths', cols : 7, total : 10),
  (class : 'three_quarters', cols : 3, total : 4),
  (class : 'content_right', cols : 3, total : 4),
  (class : 'four_fifths', cols : 4, total : 5),
  (class : 'five_sixths', cols : 5, total : 6),
  (class : 'six_sevenths', cols : 6, total : 7),
  (class : 'nine_tenths', cols : 9, total : 10),
  (class : 'five_sevenths', cols : 5, total : 7),
  (class : 'full_width', cols : 1, total : 1),
  (class : 'content_whole', cols : 1, total : 1),
); // DO NOT REMOVE ITEMS

@each $grid-column in $grid-columns {
  $grid-class: map-get($grid-column, class);
  .#{$grid-class} {
    @include col(map-get($grid-column, cols), map-get($grid-column, total));
  }
}

@include desktop {
  @each $grid-column in $grid-columns {
    $grid-class: map-get($grid-column, class);
    .desktop_#{$grid-class} {
      @include col(map-get($grid-column, cols), map-get($grid-column, total));
    }
  }
  .desktop_no_push {
    margin-left: 0;
  }
  .desktop_no_push_r {
    margin-right: 0;
  }
}

@include sm-desktop {
  @each $grid-column in $grid-columns {
    $grid-class: map-get($grid-column, class);
    .sm_desktop_#{$grid-class} {
      @include col(map-get($grid-column, cols), map-get($grid-column, total));
    }
  }
  .sm_desktop_no_push {
    margin-left: 0;
  }
  .sm_desktop_no_push_r {
    margin-right: 0;
  }
}

@include tablet {
  @each $grid-column in $grid-columns {
    $grid-class: map-get($grid-column, class);
    .tablet_#{$grid-class} {
      @include col(map-get($grid-column, cols), map-get($grid-column, total));
    }
  }
  .tablet_no_push {
    margin-left: 0;
  }
  .tablet_no_push_r {
    margin-right: 0;
  }
}

@include sm-tablet {
  @each $grid-column in $grid-columns {
    $grid-class: map-get($grid-column, class);
    .sm_tablet_#{$grid-class} {
      @include col(map-get($grid-column, cols), map-get($grid-column, total));
    }
  }
  .sm_tablet_no_push {
    margin-left: 0;
  }
  .sm_tablet_no_push_r {
    margin-right: 0;
  }
}

@include mobile {
  @each $grid-column in $grid-columns {
    $grid-class: map-get($grid-column, class);
    .mobile_#{$grid-class} {
      @include col(map-get($grid-column, cols), map-get($grid-column, total));
    }
  }
  .mobile_no_push {
    margin-left: 0;
  }
  .mobile_no_push_r {
    margin-right: 0;
  }
}

@include sm-mobile {
  @each $grid-column in $grid-columns {
    $grid-class: map-get($grid-column, class);
    .sm_mobile_#{$grid-class} {
      @include col(map-get($grid-column, cols), map-get($grid-column, total));
    }
  }
  .sm_mobile_no_push {
    margin-left: 0;
  }
  .sm_mobile_no_push_r {
    margin-right: 0;
  }
}

.row {
  position: relative;

  &:before, &:after {
    content: " ";
    display: table;
  }

  &:after {
    clear: both;
  }

  .col {
    box-sizing: border-box;
  }
}

.row.row_match_height {
  display: flex;
  flex-wrap: wrap;

  & > .col {
    flex: 1;
    flex-basis: 0;
  }

  @include mobile {
    & > .col.mobile_full_width {
      flex-basis: 100%;
      float: none;
    }
  }
}

