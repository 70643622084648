@import '../abstracts/mixins';
@import '../abstracts/variables';

/* Browsers that can't understand media queries will be 1000px. */
.containers {
  width: $container_width_default;
  max-width: 100%;
  margin: 0 auto;
  @include desktop {
    /* And those that can (and are +1200) will be a default of 1200px instead! Yaay. */
    width: $container_width_desktop;
  }
  @include sm-desktop {
    width: $container_width_sm_desktop;
  }
  @include tablet {
    width: $container_width_tablet;
  }
  @include sm-tablet {
    width: $container_width_sm_tablet;
  }
  @include mobile {
    width: $container_width_mobile;
  }
}

/* Browsers that can't understand media queries will be 1000px. */
.container-14 {
  width: 1400px;
  @apply mx-auto;

  @include sm-desktop {
    width: $container_width_sm_desktop;
  }
  @include tablet {
    width: $container_width_tablet;
  }
  @include sm-tablet {
    width: $container_width_sm_tablet;
  }
  @include mobile {
    width: $container_width_mobile;
  }
}

/* Content layout */
.section {
  padding: 45px 0;

  @include mobile {
    padding: 45px 10px;
    box-sizing: border-box;
  }
}
