@import '../abstracts/colors';
@import '../abstracts/mixins';
@import '../base/icons/variables';

.ticker_con {
  width: 100%;
  border: 0;
  background-color: $lightest-mid-grey;
  min-height: 20px;
  margin: 0 auto;
  z-index: 3;
  font-weight: 500;

  @include mobile {
    display: none;
  }

  .ticker {
    background-color: inherit;
    min-height: 38px;
    color: $black;
    position: relative;

    #tradewinds {
      float: right;

      .tradewinds_title {
        color: $vv-dark-grey;
        float: right;
        line-height: 40px;
        margin-right: 10px;
        margin-left: 10px;
        text-align: right;
      }

      @include sm-tablet {
        display: none;
      }
    }

    #news_ticker {
      float: left;
      line-height: 40px;
      max-height: 40px;
      text-align: right;
      max-width: 280px;
      overflow: hidden;
      display: inline-block;
      @include tablet {
        max-width: 268px;
      }
      @include mobile {
        padding-right: 20px;
      }
    }
  }

  .tradewinds_more_info_panel {
    display: none;
    position: absolute;
    top: 40px;
    right: 0;
    background: $lightest-mid-grey;
    width: 500px;
    z-index: 5;
    box-shadow: 2px 2px 2px -1px rgba($black, 0.2);
    @include sm-tablet {
      display: none !important; // Needs to be important to override the element level style applied in the JS logic
    }

    .tradewinds_more_info_inner {
      overflow: auto;
      max-height: 490px;
    }
  }

  .caret-close {
    background: none;

    &:after {
      font-family: $icomoon-font-family;
      content: $vv-icon-close;
      speak: none;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
  }
}

.logged_in {
  .ticker_con {
    @include mobile {
      display: block;
    }
  }
}
