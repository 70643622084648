@import '../abstracts/colors';
@import '../abstracts/mixins';
@import '../abstracts/variables';

body {
  font-family: $default-font-family;
  background-color: $white;
  font-weight: normal;
  /* Removed the 14px font size for TAILWIND use text-sm when necessary to have 14px */
}

img {
  border: 0;
}

svg {
  max-height: 100%;
}

:link:focus, :visited:focus,
input:focus,
textarea:focus {
  outline-color: $dimmed-grey;
}

/* Clearing */
.cf:before,
.cf:after {
  content: " ";
  display: table;
}

.cf:after, .cb {
  clear: both;
}

.clear {
  clear: both;
  padding: 0;
  margin: 0;
  overflow: hidden;
}

.spacer {
  margin-left: 2px;
}

#everythingbarthefooter {
  min-height: 100%;
  height: auto;

  img {
    max-width: 100%;
  }
}

body, #everythingbarthefooter, .content {
  @include mobile {
    min-width: 320px;
    padding-left: 0;
    padding-right: 0;
  }
  @include sm-mobile {
    min-width: 0 !important;
  }
}

template {
  display: none !important;
}

.reset {
  border: 0;
  background: none;
  line-height: 1.15;
  margin: 0;
  padding: 0;
  text-align: left;
  overflow: visible;
  text-transform: none;
  outline: none;
}
