@import '../abstracts/mixins';

@mixin scrollable($device-descriptor) {
  .#{$device-descriptor}_scroll {
    overflow-x: auto;
    overflow-y: visible;
    height: auto !important;

    table.scrollable {
      table-layout: auto;
      width: max-content;
    }
  }
}

@mixin auto-height($device-descriptor) {
  div.#{$device-descriptor}_hauto {
    height: auto;
    min-height: 0;
  }
}

.ib {
  display: inline-block;
}

.il, .dil {
  display: inline;
}

.db {
  display: block;
}

.dthg {
  display: table-header-group;
}

.dn,
.hidden, .hide, div.toggle_hidden, span.toggle_hidden,
.ui_expiry_notice, #popup, .ui-helper-hidden-accessible, .hidden_post, .scroll_hide, .dz-preview, .sp-dd {
  display: none;
}

.mobile_only, .desktop_hide {
  display: none !important;
}

.tablet_hide.inline {
  display: inline !important;
}

/** Display on mobile only NO IMPORTANT (as you then have to do hacky fixes to hide it) **/
.d-mobile {
  display: none;
}

.print_only {
  display: none;
}

@include desktop {
  .desktop_show {
    display: inherit !important;
  }
}

@include sm-desktop {
  .desktop_hide {
    display: inherit !important;
  }
  .sm_desktop_hide {
    display: none !important;
  }
  .sm_desktop_show {
    display: inherit !important;
  }
}

@include tablet {
  .desktop_hide, .sm_desktop_hide {
    display: inherit !important;
  }
  .tablet_hide {
    display: none !important;
  }
  .tablet_show {
    display: inherit !important;
  }
}

@include sm-tablet {
  .tablet_hide.inline {
    display: inline;
  }
  .desktop_hide, .sm_desktop_hide, .tablet_hide {
    display: inherit !important;
  }
  .sm_tablet_hide {
    display: none !important;
  }
  .sm_tablet_show {
    display: inherit !important;
  }
}

@include mobile {
  .tablet_hide.inline {
    display: inline;
  }
  .desktop_hide, .sm_desktop_hide, .tablet_hide, .sm_tablet_hide {
    display: inherit !important;
  }
  .mobile_hide, .phone_hide {
    display: none !important;
  }
  .mobile_show, .mobile_only {
    display: initial !important;
  }
  .d-mobile {
    display: initial;
  }
}

.full_height {
  height: 100%;
}

.hrst {
  min-height: 0;
  max-height: initial;
  height: auto;

  &.imp {
    min-height: 0 !important;
    max-height: initial !important;
    height: auto !important;
  }
}

@include desktop {
  $device: 'desktop';

  @include scrollable($device);

  @include auto-height($device);
}

@include sm-desktop {
  $device: 'sm_desktop';

  @include scrollable($device);

  @include auto-height($device);
}

@include tablet {
  $device: 'tablet';

  @include scrollable($device);

  @include auto-height($device);
}

@include sm-tablet {
  $device: 'sm_tablet';

  @include scrollable($device);

  @include auto-height($device);
}

@include mobile {
  $device: 'mobile';

  @include scrollable($device);

  @include auto-height($device);

  .mobile_fixed100 .mobile_scroll {
    margin-left: 100px;
  }
  .mobile_fixed100 .mobile_scroll th:first-child, .mobile_fixed100 tbody tr td:first-child {
    position: absolute;
    left: 0;
    width: 88px;
  }
  .mobile_fixed100 .mobile_scroll th:first-child {
    height: 29px;
    line-height: 28px;
  }
  .mobile_fixed100 tbody tr td:first-child {
    height: 30px;
    line-height: 29px;
  }
}

[v-cloak] {
  display: none;
}
