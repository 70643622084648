@import '../abstracts/colors';

/* MAP FIXES */
#polyalert_medi_map img, #polyalert_medi_map div, #polyalert_medi_map a,
#position_list_medi_map img, #position_list_medi_map div, #position_list_medi_map a,
#position_list_results_medi_map img, #position_list_results_medi_map div, #position_list_results_medi_map a,
#journey_medi_map img, #journey_medi_map div, #journey_medi_map a,
#stoppage_medi_map img, #stoppage_medi_map div, #stoppage_medi_map a,
#location_small_map img, #location_small_map div, #location_small_map a,
.vvq_google_map img, .vvq_google_map div, .vvq_google_map a,
tr[data-map] a, tr[data-map] img, tr[data-map] div, .port_map img, .port_map div, .port_map a,
#ownership_chart, .map_wrapper, .gMapCONTACT, .gMapCONTACT img, .gMapCONTACT div, .gMapCONTACT a {
  max-width: none !important;
}
#polyalert_medi_map,
#position_list_medi_map,
#position_list_results_medi_map,
#journey_medi_map,
#location_small_map,
#stoppage_medi_map {
  display: block;
  position: absolute;
  left: -999999px;
  z-index: 0;
  overflow: hidden;
  height: 400px;
}
#polyalert_medi_map,
#location_small_map {
  width: 100%;
}
#stoppage_medi_map,
#journey_medi_map {
  right: -999999px;
  left: auto;
}
tr[data-map] {
  height: auto;
  td,
  div {
    height: auto;
    overflow: initial;
  }
}
td.position_list_show_path {
  &:before {
    content: "\002b";
    color: $vv-dark-red;
    font-size: 2em;
    vertical-align: middle;
  }
  &.open_path:before {
    content: "\00d7";
  }
}
/*Stoppages / position list items on valuation.php but here for precedence*/
.position_list_map_container {
  position: relative;
  left: 0;
  width: 100%;
  height: 420px;
  display: none;
  z-index: 1;
  #position_list_medi_map {
    height: 100%;
    position: relative;
    float: right;
  }
}
.position_list_results_map_container #position_list_results_medi_map {
  width: 100%;
  height: 100%;
  position: relative;
  float: right;
}
.stoppage_map_container #stoppage_medi_map {
  height: 100%;
  position: relative;
  float: right;
}
.location_small_map_container #location_small_map {
  width: 100%;
  height: 100%;
  position: relative;
  float: right;
}
.journey_map_container #journey_medi_map,
#stoppages_more_container {
  height: 100%;
  float: left
}
.vvq_google_map {
  display: block;
  z-index: 0;
  overflow: hidden;
  min-height: 200px;
}
