@import "../abstracts/colors";

/* SVG Risk Icons */
.risk-icon  {
  &.rating-0, &.rating-g {
    fill: $demo-green;
  }

  &.rating-1, &.rating-y {
    fill: $vv-dark-yellow;
  }

  &.rating-1000, &.rating-o {
    fill: $vv-orange;
  }

  &.rating-1000000, &.rating-r {
    fill: $vv-dark-red;
  }

  svg {
    display: inline;
    vertical-align: middle;
    width: 14px;
  }

  &.warn svg {
    vertical-align: middle;
    width: 16px;
  }
}
