@import "../../abstracts/colors";
@import '../../abstracts/mixins';
@import "../../abstracts/variables";

//
// Regular Checkbox Labels (Red)
//

.checkbox {
  width: 10px;
  height: 10px;
  padding: 0;
  margin: 0;
}

.checkbox-styled {
  input[type="checkbox"]:checked + label,
  input[type="checkbox"]:checked + label:hover {
    color: $vv-dark-red;
    border-color: $vv-dark-red;
  }
}

//
// Large Checkbox Labels
//
.checkbox-styled.checkbox-lg {
  input[type="checkbox"]:checked + label,
  input[type="checkbox"]:checked + label:hover,
  input[type="checkbox"]:checked + label .typography {
    color: $vv-dark-red;
    border-color: $vv-dark-red;
  }

  input[type="checkbox"] + label {
    height: calc(100% - 2px);
    width: calc(100% - 5.2em);
    min-height: 50px;
    line-height: 1.8em;
    border: 1px solid $lighter-mid-grey;
    border-radius: 3px;
    margin: 0 0 10px 0;

    &:before {
      top: calc(50% - 14px);
      left: 1em;
      @include mobile {
        left: 0.75em;
      }
    }

    &:after {
      top: calc(50% - 10px);
      left: 1em;
      @include mobile {
        left: 0.85em;
      }
    }
  }
}

.checkbox-styled.checkbox-lg input[type="checkbox"] + label {
  padding: 15px 10px 15px 1px;
  margin: 0;
  width: 100%;
  user-select: none;
}

.checkbox-styled label {
  box-sizing: border-box;
}

.checkbox-styled label:before, .checkbox-styled label:before {
  border-radius: 5px !important;
  border-color: $dimmed-grey !important;
  left: 20px !important;
}

.checkbox-styled label:after, .checkbox-styled label:after {
  left: 27px !important;
  font-size: 14px !important;
  top: calc(50% - 8px) !important;
}

.checkbox-styled p {
  margin: 0;
  padding: 0 0 0 65px;
  line-height: 1.5;
}

.checkbox-styled label.al {
  float: none; /** Override some craziness done in _forms.scss */
  padding: 0;
  margin: 0;
  width: auto;
}

