@import '../abstracts/colors';
@import '../abstracts/mixins';

// Tabbing (Selection tabs)
.tabcontainer {
  min-height: 350px;
  display: block;

  &.innercontainer {
    width: 100%;
  }

  @include sm-tablet {
    height: auto !important;
    padding-bottom: 20px;
  }
}

.tabstrip {
  float: none !important; /* No floating */

  a {
    display: inline-block;
  }
}

.tabbing {
  font-weight: normal;
  font-variant: normal;
  font-style: normal;
  text-decoration: none;

  &.tabbing_nostyle {
    padding-bottom: 0;
    padding-top: 0;

    &.dim:hover {
      border-bottom-color: $dimmed-grey;
    }
  }

  &:not(.tabbing_nostyle) {
    a:not(.more_info),
    div.tablinks:not(.more_info) {
      border-width: 1px;
      border-style: solid;
      border-top-color: $vv-dark-red;
      border-bottom-color: $vv-dark-red;
      border-left-color: $vv-dark-red;
      border-right-color: $vv-dark-red;
      border-radius: 3px 3px 0 0;
      padding: 5px 6px 3px;
      margin-left: 0;
      cursor: pointer;
      border-bottom: 0;
      white-space: nowrap;

      &.active,
      &:hover {
        text-decoration: none;
        color: $white;
        background-color: $vv-dark-red;
      }

      &.on_right {
        float: right;
        margin-top: -6px;
      }

      &.seasure_only {
        border-color: $vv-seasure-blue;
        color: $vv-seasure-blue;
        border-bottom-color: $vv-dark-red;

        &.active,
        &:hover {
          background-color: $vv-seasure-blue;
          border-color: $vv-seasure-blue;
          color: $white;
        }
      }

      &.dim {
        border-top-color: $dimmed-grey;
        border-left-color: $dimmed-grey;
        border-right-color: $dimmed-grey;
        color: $dimmed-grey;

        &.active,
        &:hover {
          text-decoration: none;
          color: $white;
          background-color: $lighter-dark-grey;
        }
      }

      .disabled_tab {
        background-color: $dark-mid-grey;

        &:hover {
          cursor: default;
          background-color: $dark-mid-grey !important;
          color: $vv-dark-red !important;
        }
      }
    }

    &.tab_spacing a {
      margin-right: 3px;
    }
  }

  a,
  div.tablinks {
    &.nostyle {
      border: 0;
      margin: 0;

      &:hover {
        color: inherit;
        background-color: transparent;
        padding: 0;
      }
    }

    .tabnew {
      font-size: 0.6em;
      vertical-align: text-top;
    }

    .hide_dot {
      color: transparent;
    }
  }

  div.tablinks {
    display: inline-block;
    color: $vv-dark-red;

    &.active,
    &:hover {
      a.more_info {
        color: $white;
      }
    }
  }

  a {
    display: inline-block;
    margin-top: 5px;

    @include sm-desktop {
      margin-bottom: -1px;
      &.on_right {
        float: none;
        margin-top: 5px;
      }
    }

    @include sm-tablet {
      border-radius: 0;
      white-space: nowrap;
      float: none !important;
      padding: 5px;
      margin-top: 10px;
      &.on_right {
        margin-top: 10px;
      }
    }
  }

  .count {
    margin-left: 5px;
    display: inline-block;
    text-align: right;
  }

}

.subtab-button-container {
  a {
    border-bottom: 2px solid transparent;
  }

  &.active {
    a {
      border-color: $vv-dark-red;
      color: $vv-dark-red;
    }
  }
}

.tabbing_nostyle {
  margin-left: 0;
  width: auto;
  color: $black;

  a,
  div.tablinks {
    border: 0;
    margin: 0;

    &:not(.more_info) {

      &.active,
      &:hover {
        border-bottom: 2px solid $vv-dark-red;
        background-color: transparent;
        color: $vv-dark-red;
      }

      &.seasure_only {
        &.active,
        &:hover {
          color: $vv-seasure-blue;
          border-bottom: 2px solid $vv-seasure-blue;
        }
      }

      &.dim {
        border-left-color: $dimmed-grey;
        border-right-color: $dimmed-grey;
        color: $dimmed-grey;

        &:hover,
        &.active {
          border-bottom-color: $dimmed-grey;
        }
      }
    }
  }
}

.tabcontent {
  left: 0;
  border: none !important;
  visibility: inherit;

}

.tabactive {
  position: inherit;
}

.flex {
  .mobile_one_half {
    select {
      @include mobile {
        width: 100%;
      }

      &#saved_searches {
        width: 20em;
      }
    }
  }
}

.search_results_outer, .tabcontent {
  .tabbing {
    a {
      display: inline-block;
      margin-right: 3px;
    }
  }

  .pagination_container {
    display: flex;
    align-items: center;

    .actions {
      margin-right: 1em;
    }

    .pagination {
      margin-left: auto;
    }

    @include tablet {
      flex-direction: column;
      width: 100%;

      div {
        flex: 1 1 auto;
      }

      .actions {
        margin-top: 0.2em;
        margin-bottom: 0.5em;
        margin-right: 0;

        select {
          width: 96%;
        }
      }

      .pagination {
        margin-top: 1.5em;
        margin-bottom: 0.5em;
        margin-left: initial;
      }

      div {
        width: 100%;
      }

      div.limiter {
        width: auto;
      }
    }

    @include mobile {
      .actions, .pagination {
        select {
          width: 97%;
          margin-bottom: 0.5em;
        }
      }

      .pagination {
        clear: both;
        width: 100%;
        margin-top: 1em;
      }

      .pagination, .vv-pagination {
        .total {
          padding: 2px 6px 2px 0;
        }
      }
    }

    @media (max-width: 364px) {
      .pagination {
        .limiter {
          width: 98%;
          margin-top: 1em;

          select {
            padding: 2px 4px 2px 4px;
          }
        }
      }
    }

    @media (max-width: 1023px) {
      .pagination {
        margin-left: initial;
      }
    }
  }
}

.tabstrip {
  @include mobile {
    text-align: left !important;
    border-bottom: 0;
    overflow: hidden;

    &:not(.tabbing_nostyle) {
      a {
        margin-top: 8px;
        display: inline-block;
        padding: 6px 8px !important;
        border-bottom: solid 1px $vv-red !important;
        position: relative !important;

        &:before {
          content: '';
          background-color: $vv-red;
          position: absolute;
          bottom: -1px;
          left: -50%;
          width: 999%;
          height: 1px;
        }
      }
    }
  }
}

.linkrow {
  float: left;

  a {
    margin: 2px 3px 0 0;
    display: inline-block;

    &:hover {
      text-decoration: none;
    }

    &.active {
      border-bottom: 2px solid $vv-dark-red;
    }
  }
}

.tabcontent {
  display: none;

  &.tabactive {
    display: block;
  }
}
