@import '../abstracts/colors';

$border-sides: top,
right,
bottom,
left;
$border-styles: none,
solid,
dotted,
dashed;
$max-border-width: 2;

/* Borders */
.grey_border {
  border-width: 1px;
  border-style: solid;
  border-color: $vv-darkest-mid-grey;
}

.black-border {
  border-color: $black;
}

.risk_white {
  border-color: $white;
}

.risk_red {
  border-color: $vv-light-red;
}

.risk_yellow {
  border-color: $lighter-pale-yellow;
}

.tbl_border_grey {
  border-color: $dark-mid-grey;
}

abbr {
  border-bottom: 1px dotted $darker-grey;
}

.bn {
  border: none !important;
}

.bdbn {
  border-bottom: none;
}

.bdtn {
  border-top: none;
}

.bl {
  border-width: 0;
  border-left-width: 1px !important;
}

.br {
  border-width: 0;
  border-right-width: 1px !important;
}

.bs {
  border-style: solid !important;
}

.bds {
  border-style: dashed !important;
}

.bd {
  border-style: dotted !important;
}

.brs {
  border-width: 0;
  border-right-width: 1px;
  border-right-style: solid;
}

.bls {
  border-width: 0;
  border-left-width: 1px;
  border-left-style: solid;
}

.bbs {
  border-width: 0;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}

.vv-border {
  border-width: 1px;

  &-spacing {
    &-none {
      border-spacing: 0;
    }
  }

  &-collapse {
    border-collapse: collapse;
  }
}

.line {
  height: 2px;
  width: 30px;
  display: inline-block;
  background-color: transparent;
  margin-bottom: 2px;

  &.green {
    background-color: green;
    border-color: black !important;
    border-style: solid;
    border-width: 1px;
  }

  &.red {
    background-color: red;
    border-color: black !important;
    border-style: solid;
    border-width: 1px;
  }

  &.white {
    background-color: white;
    border-color: black !important;
    border-style: solid;
    border-width: 1px;
  }
}

.bc {
  &-lightgrey {
    border-color: $lightest-mid-grey;
  }

  &-grey {
    border-color: $vv-darkest-mid-grey;
  }

  &-midgrey {
    border-color: $vv-darkest-mid-grey;
  }

  &-lightergrey {
    border-color: $lighter-mid-grey;
  }
}

/* Border Widths */

.bw-2 {
  border-width: 2px;
}

/* Border Radius */
.br-3 {
  border-radius: 3px;
}

.vv-rounded-full {
  border-radius: 9999px;
}

.vv-border {
  border-width: 1px;
  border-style: solid;
  border-color: $lighter-mid-grey;
}

@each $val in (top, right, bottom, left) {
  .vv-border-#{str_slice($val, 0, 1)} {
    border-#{$val}: 1px solid $lighter-mid-grey;
  }

  @each $w in (0, 1, 2, 3, 4, 5) {
    .vv-border-#{str_slice($val, 0, 1)}-#{$w} {
      border-#{$val}: #{$w}px solid $lighter-mid-grey;
    }
  }
}

@include mobile {
  @each $val in (top, right, bottom, left) {
    .vv-sm-border-#{str_slice($val, 0, 1)} {
      border-#{$val}: 1px solid $lighter-mid-grey;
    }

    @each $w in (0, 1, 2, 3, 4, 5) {
      .vv-sm-border-#{str_slice($val, 0, 1)}-#{$w} {
        border-#{$val}: #{$w}px solid $lighter-mid-grey;
      }
    }
  }
}

@include tablet {
  @each $val in (top, right, bottom, left) {
    .vv-md-border-#{str_slice($val, 0, 1)} {
      border-#{$val}: 1px solid $lighter-mid-grey;
    }

    @each $w in (0, 1, 2, 3, 4, 5) {
      .vv-md-border-#{str_slice($val, 0, 1)}-#{$w} {
        border-#{$val}: #{$w}px solid $lighter-mid-grey;
      }
    }
  }
}

@include desktop {
  @each $val in (top, right, bottom, left) {
    .vv-lg-border-#{str_slice($val, 0, 1)} {
      border-#{$val}: 1px solid $lighter-mid-grey;
    }

    @each $w in (0, 1, 2, 3, 4, 5) {
      .vv-lg-border-#{str_slice($val, 0, 1)}-#{$w} {
        border-#{$val}: #{$w}px solid $lighter-mid-grey;
      }
    }
  }
}

/**
 * Create classes for border line styles on each side
 * The existing bbs, bbr, etc. set the widths of the other sides to 0
 * e.g. .border-top-solid, .border-left-dashed
 */
@each $style in $border-styles {
  .vv-border-#{$style} {
    border-style: $style;
  }

  @each $side in $border-sides {
    .vv-border-#{$side}-#{$style} {
      border-#{$side}-style: $style;
    }
  }
}

/**
 * Create classes for border widths up until the cap
 * e.g. .border-width-1, .border-right-width-2
 */
@for $i from 1 through $max-border-width {
  .vv-border-width-#{$i} {
    border-width: #{$i}px;
  }

  @each $side in $border-sides {
    .vv-border-#{$side}-width-#{$i} {
      border-#{$side}-width: #{$i}px;
    }
  }
}

$borderColors: ('black': $black,
  'white': $white,
  'mid-grey': $mid-grey,
  'lightest-mid-grey': $lightest-mid-grey,
  'lighter-grey': $lighter-grey,
  'lighter-mid-grey': $lighter-mid-grey,
  'vv-darkest-mid-grey': $vv-darkest-mid-grey,
  'vv-dark-red': $vv-dark-red,
  'vv-red': $vv-red,
  'vv-dark-teal': $vv-dark-teal
);

@each $name,
$val in $borderColors {
  .vv-border-#{$name} {
    border-color: $val !important;
  }
}

// So IE11 can have border opacity
.border-white-opacity-25 {
  border: 1px solid rgba(255, 255, 255, .25);
}
