@import "../abstracts/colors";

$test-mode-notice-yellow: #e2d20b;

#test_mode_notice_container {
  text-align: center;
  background: repeating-linear-gradient(45deg, $test-mode-notice-yellow, $test-mode-notice-yellow 10px, $black 10px, $black 20px);

  span {
    font-size: 3em;
    font-weight: bold;
    color: $white;
    padding: 0 10px 0 10px;
    background-color: $black;
  }
}
