@import '../abstracts/colors';
@import '../abstracts/mixins';
@import '../abstracts/variables';

p {
  &.span {
    font-weight: bold;
    text-decoration: underline;
  }

  &.helper {
    text-align: right;
    font-weight: bold;
    line-height: 10px;
    vertical-align: text-top;
  }
}

// Font sizes. Default 12, 14 and 16. Use DefaultXX.
.default16 {
  font-size: 1rem;
}

.default14 {
  font-size: 0.875rem;
}

.default12 {
  font-size: 0.75rem;
}

@for $i from 8 through 20 {
  .font#{$i} {
    font-size: #{$i}px;
  }
}

.font28 {
  font-size: 28px;
}

.font42 {
  font-size: 42px;
}

// Font styling
.em,
.vv-italic {
  font-style: italic;
}

.fw-normal {
  font-weight: normal;
}

b,
strong,
.strong,
.b,
#menu_form p.error,
.fw-bold,
.diff {
  font-weight: bold;
}

.vv-underline {
  text-decoration: underline;
}

.hover-underline:hover,
.hover-underline:active {
  text-decoration: underline;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sup {
  top: -.5em;
}

sub {
  bottom: -.25em;
}

.vv-uppercase {
  text-transform: uppercase;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.phone_number,
.nobr {
  white-space: nowrap;
}

/* Line Heights
 * Usage: lh-{height}{extension}
 * Extensions include:
 *    % = -p
 *    em = -em
 */
$heights: (1, 1.2, 1.5, 1em, 2); // DO NOT REMOVE

@each $height in $heights {
  $height-class: #{convert-height-to-class($height)};

  .lh-#{$height-class} {
    line-height: #{$height};
  }
}

// Deprecated line heights
.lh100 {
  line-height: 1;
}

.lh150 {
  line-height: 1.5;
}

/* Font sizes */
.uuubump,
span.uuubump,
a.uuubump,
p.uuubump {
  font-size: $font-size-xxs; // 0.60em
}

.uubump,
span.uubump,
a.uubump,
p.uubump {
  font-size: $font-size-xs; // 0.77em
}

.ubump,
span.ubump,
a.ubump,
p.ubump {
  font-size: $font-size-s; // 0.9em
}

.bump,
span.bump,
a.bump,
p.bump {
  font-size: $font-size-m; // 1.1em
}

.bbump,
span.bbump,
a.bbump,
p.bbump {
  font-size: $font-size-l; // 1.2em
}

.bbbump,
span.bbbump,
a.bbbump,
p.bbbump {
  font-size: $font-size-xl; // 1.3em
}

.bbbbump,
span.bbbbump,
a.bbbbump,
p.bbbbump {
  font-size: $font-size-xxl; // 1.4em
}

.xbump,
span.xbump,
a.xbump,
p.xbump {
  font-size: $font-size-xxxl; // 1.5em
}

.xxbump,
span.xxbump,
a.xxbump,
p.xxbump {
  font-size: $font-size-xxxxl; // 1.6em
}

.text-vv-dark-teal {
  color: $vv-dark-teal;
}

.red_text {
  color: $vv-red;
}

.section.red_bg h2,
.section.alternate_bg h2,
.section.locations_bg h2,
.section.red_bg h2 {
  color: $white;
}

.text-muted {
  color: $lighter-dark-grey !important;
}

.text-vv-dark-grey {
  color: $vv-dark-grey;
}

.idwal {
  color: $idwal-primary;
}

/* Text/Border Colours - not sure where to put these at the moment */
.red {
  @include border-text-color($vv-dark-red);
}

.alternate_colour {
  @include border-text-color($vv-dark-teal);
}

.darkgrey {
  @include border-text-color($darkest-grey);
}

.lgrey {
  @include border-text-color($vv-light-dark-grey);
}

.grey {
  @include border-text-color($vv-dark-grey);
}

.usemegrey {
  @include border-text-color($lighter-dark-grey);
}

.usemegrey-simple {
  @include simple-text-color($lighter-dark-grey);
}

.useme2grey {
  @include border-text-color($dimmed-grey);
}

.useme3grey {
  @include border-text-color($vv-darkest-mid-grey);
}

.useme4grey {
  @include border-text-color($vv-light-dark-grey);
}

.lightergrey {
  @include border-text-color($lighter-mid-grey);
}

.lightgrey {
  // Darker than lightergrey, should be renamed
  @include border-text-color($lighter-grey);
}

.black {
  @include border-text-color($black);
}

.white {
  @include border-text-color($white);
}

.darkyellowtext {
  @include border-text-color($vv-dark-yellow);
}

.midredtext {
  @include border-text-color($vv-red);
}

.positive {
  color: $positive-green;
}

.negative {
  color: $negative-red;
}

.darkest-negative {
  color: $vv-darkest-red;
}

.text-darker-grey {
  color: $darker-grey
}

.text-vv-light-dark-grey {
  color: $vv-light-dark-grey;
}

.text-darkest-grey {
  color: $darkest-grey;
}

.text-vv-dark-red {
  color: $vv-dark-red;
}

.vv-text-xl {
  font-size: 21px;
}

.vv-text-2xl {
  font-size: 23px;
}

.vv-text-3xl {
  font-size: 37px;
}

.vv-text-4xl {
  font-size: 48px;
}

// Puts an ellipsis at the end of the sentance if more than 21 characters on small tablet and below - curently used for display name
.sm-overflow-ellipsis {
  @include sm-tablet {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 21ch;
  }

  @include sm-mobile {
    max-width: 17ch;
  }
}

/**
 * Text alignment
 * This function creates all text alignment position options for all screensizes
 * E.g: Text align center: .ac | .xl-ac | .lg-ac | .md-ac | .sm-ac | .xs-ac | .xxs-ac
 * Usage Document: http://confluence.vvdev.co.uk/display/DEV/Sass+layout#Sasslayout-text-alignment-classes
 */
$alignments: (center, left, right, justify); // DO NOT REMOVE

// Global text alignment
@each $alignment in $alignments {
  $alignment-class: str-slice($alignment, 0, 1);

  .a#{$alignment-class},
  .ta-#{$alignment} {
    text-align: #{$alignment};
  }
}

// Desktop only text alignment - 1201px +
@each $alignment in $alignments {
  $alignment-class: str-slice($alignment, 0, 1);

  @include desktop {

    .xl-a#{$alignment-class},
    .xl-ta-#{$alignment} {
      text-align: #{$alignment};
    }
  }
}

// Upto small desktop text alignment - upto 1200px
@each $alignment in $alignments {
  $alignment-class: str-slice($alignment, 0, 1);

  @include sm-desktop {

    .lg-a#{$alignment-class},
    .lg-ta-#{$alignment} {
      text-align: #{$alignment};
    }
  }
}

// Upto tablet text alignment - upto 1000px
@each $alignment in $alignments {
  $alignment-class: str-slice($alignment, 0, 1);

  @include tablet {

    .md-a#{$alignment-class},
    .md-ta-#{$alignment} {
      text-align: #{$alignment};
    }
  }
}

// Upto small tablet text alignment - upto 790px
@each $alignment in $alignments {
  $alignment-class: str-slice($alignment, 0, 1);

  @include sm-tablet {

    .sm-a#{$alignment-class},
    .sm-ta-#{$alignment} {
      text-align: #{$alignment};
    }
  }
}

// Upto mobile text alignment - upto 560px
@each $alignment in $alignments {
  $alignment-class: str-slice($alignment, 0, 1);

  @include mobile {

    .xs-a#{$alignment-class},
    .xs-ta-#{$alignment} {
      text-align: #{$alignment};
    }
  }
}

// Upto small mobile text alignment - upto 350px
@each $alignment in $alignments {
  $alignment-class: str-slice($alignment, 0, 1);

  @include sm_mobile {

    .xxs-a#{$alignment-class},
    .xxs-ta-#{$alignment} {
      text-align: #{$alignment};
    }
  }
}

.ellipsis-text {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  /** IE6+, Firefox 7+, Opera 11+, Chrome, Safari **/
  width: 99%
}

/* Use Romaji font first so that non-kanji characters render well. Characters such as シ will then render using the falback font. */
.about_jp p,
.about_jp h1,
.about_jp h2,
.about_jp h3,
.about_jp input {
  /* Japanese */
  font-family: "Open Sans", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", Osaka, "メイリオ", Meiryo, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif !important;
}

.about_kr p,
.about_kr h1,
.about_kr h2,
.about_kr h3,
.about_kr input {
  /* Korean */
  font-family: "Open Sans", Helvetica, Arial, Malgun Gothic, "돋움", Dotum, sans-serif;
}

.error {
  padding: 0;
  margin: 0;
  color: $vv-dark-red;
}

// Bold text without layout shift
.font-stroke-bold {
  -webkit-text-stroke-width: 1.2px;
}

@include mobile {
  .sm-font30 {
    font-size: 30px;
  }
}
