@import '../abstracts/mixins';

.zindex0 {
  z-index: 0;
}
.zindex1 {
  z-index: 1;
}
.zindex2 {
  z-index: 2;
}
.zindex3 {
  z-index: 3;
}
.center {
  margin: 0 auto;
  float: none;
}
.pos-top {
  top: 0;
}
.pos-bottom {
  bottom: 0;
}
.pos-left {
  left: 0;
}
.pos-right {
  right: 0;
}
.pos-top-5 {
  top: 5px;
}

/**
 * Positions
 * This function creates all element position options for all screensizes
 * E.g: Absolute positioned element: .pabs | .xl-pabs | .lg-pabs | .md-pabs | .sm-pabs | .xs-pabs | .xxs-pabs
 * Usage Document: http://confluence.vvdev.co.uk/display/DEV/Sass+layout#Sasslayout-position-classes
 */
$positions: (absolute, fixed, inherit, initial, relative, static, sticky, unset); // DO NOT REMOVE

// Global element positions
@each $position in $positions {
  $position-class: str-slice($position, 0, 3);
  .p#{$position-class} {
    position: #{$position};
  }
}

// Desktop only text alignment - 1201px +
@each $position in $positions {
  $position-class: str-slice($position, 0, 3);
  @include desktop {
    .xl-p#{$position-class} {
      position: #{$position};
    }
  }
}

// Upto small desktop text alignment - upto 1200px
@each $position in $positions {
  $position-class: str-slice($position, 0, 3);
  @include sm-desktop {
    .lg-p#{$position-class} {
      position: #{$position};
    }
  }
}

// Upto tablet text alignment - upto 1000px
@each $position in $positions {
  $position-class: str-slice($position, 0, 3);
  @include tablet {
    .md-p#{$position-class} {
      position: #{$position};
    }
  }
}

// Upto small tablet text alignment - upto 790px
@each $position in $positions {
  $position-class: str-slice($position, 0, 3);
  @include sm-tablet {
    .sm-p#{$position-class} {
      position: #{$position};
    }
  }
}

// Upto mobile text alignment - upto 560px
@each $position in $positions {
  $position-class: str-slice($position, 0, 3);
  @include mobile {
    .xs-p#{$position-class} {
      position: #{$position};
    }
  }
}

// Upto small mobile text alignment - upto 350px
@each $position in $positions {
  $position-class: str-slice($position, 0, 3);
  @include sm_mobile {
    .xxs-p#{$position-class} {
      position: #{$position};
    }
  }
}

/**
 * Float Position
 * This function creates all float position options for all screensizes
 * E.g: Float Left : .fl | .xl-fl | .lg-fl | .md-fl | .sm-fl | .xs-fl | .xxs-fl
 * Usage Document: http://confluence.vvdev.co.uk/display/DEV/Sass+layout#Sasslayout-float-position-classes
 */
$float-positions: (left, right, none); // DO NOT REMOVE

// Global text alignment
@each $float-position in $float-positions {
  $float-position-class: str-slice($float-position, 0, 1);
  .f#{$float-position-class}, .pull-#{$float-position} {
    float: #{$float-position};
  }
}

// Desktop only text alignment - 1201px +
@each $float-position in $float-positions {
  $float-position-class: str-slice($float-position, 0, 1);
  @include desktop {
    .xl-f#{$float-position-class}, .xl-pull-#{$float-position},
    .desktop_f#{$float-position-class} { // to be replaced with xl-f around code base
      float: #{$float-position};
    }
  }
}

// Upto small desktop text alignment - upto 1200px
@each $float-position in $float-positions {
  $float-position-class: str-slice($float-position, 0, 1);
  @include sm-desktop {
    .lg-f#{$float-position-class}, .lg-pull-#{$float-position},
    .sm_desktop_f#{$float-position-class} { // @deprecated - @see to be replaced with lg-f around code base
      float: #{$float-position};
    }
  }
}

// Upto tablet text alignment - upto 1000px
@each $float-position in $float-positions {
  $float-position-class: str-slice($float-position, 0, 1);
  @include tablet {
    .md-f#{$float-position-class}, .md-pull-#{$float-position},
    .tablet_f#{$float-position-class} { // @deprecated - @see to be replaced with md-f around code base
      float: #{$float-position};
    }
  }
}

// Upto small tablet text alignment - upto 790px
@each $float-position in $float-positions {
  $float-position-class: str-slice($float-position, 0, 1);
  @include sm-tablet {
    .sm-f#{$float-position-class}, .sm-pull-#{$float-position},
    .sm_tablet_f#{$float-position-class} { // @deprecated - @see to be replaced with sm-f around code base
      float: #{$float-position};
    }
  }
}

// Upto mobile text alignment - upto 560px
@each $float-position in $float-positions {
  $float-position-class: str-slice($float-position, 0, 1);
  @include mobile {
    .xs-f#{$float-position-class}, .xs-pull-#{$float-position},
    .mobile_f#{$float-position-class} { // @deprecated - @see to be replaced with xs-f around code base
      float: #{$float-position};
    }
  }
}

// Upto small mobile text alignment - upto 350px
@each $float-position in $float-positions {
  $float-position-class: str-slice($float-position, 0, 1);
  @include sm_mobile {
    .xxs-f#{$float-position-class}, .xxs-pull-#{$float-position},
    .sm_mobile_f#{$float-position-class} { // @deprecated - @see to be replaced with xxs-f around code base
      float: #{$float-position};
    }
  }
}
