@import '../abstracts/colors';
@import '../abstracts/mixins';

/* Advanced Search Dropdowns */
.advanced_search {
  width: 100%;
  margin-top: 8px;
  font-size: 0.75rem;

  td,
  th {
    text-align: left;
    vertical-align: middle;
    padding: 8px;
    border: 0 none;

    &:first-child {
      border-left: 8px solid transparent;
    }

    &:last-child {
      border-color: transparent;
    }
  }

  th {
    width: 120px;
  }

  thead {
    th {
      vertical-align: top;
    }

    td {
      vertical-align: bottom;
    }
  }

  tbody th.ar {
    text-align: right;
    font-weight: normal;
    padding-right: 0;
  }

  td {
    &.advanced_not {
      text-align: right;
    }

    &.advanced_fields {
      padding-bottom: 0;
      padding-left: 20px;
    }
  }

  input,
  select,
  textarea {
    float: none;
    margin: 0 8px;
  }

  .odd {
    background-color: $lighter-mid-grey;

    th,
    td {
      &:first-child,
      &:last-child {
        border-color: $lighter-mid-grey;
      }
    }
  }

  .not {
    th,
    td {
      &:first-child,
      &:last-child {
        border-color: $darker-light-pink;
      }
    }

    .odd {
      th,
      td {
        &:first-child,
        &:last-child {
          border-color: $darkest-light-pink;
        }
      }
    }
  }
}

#table_vessels.advanced_results {
  width: 100%;
  overflow-x: scroll;
}

.advanced_operator {
  width: 120px;

  select {
    width: 135px;
  }

  input,
  select,
  textarea {
    margin: 0;
  }
}

.advanced_criteria {
  input,
  select,
  textarea {
    max-width: 250px;
    width: 250px;
    width: 250px;

    &:first-child {
      margin-left: 0;
    }

    &.highlighted {
      background-color: $light-pink;
    }
  }

  input {
    &.number,
    &.year {
      width: 55px;
    }

    &.date {
      width: auto;
    }
  }

  select {
    &.month {
      width: auto;
      margin-right: 0;
    }

    &.yesno {
      width: 70px;
      margin-right: 0;
    }

    &.dir {
      width: 50px;
      margin-left: 0;
    }
  }
}

.advanced_more,
.advanced_not,
.advanced_remove {
  width: 50px;
  padding: 8px 0 !important;
}

.advanced_add {
  width: 90px; /* if you're changing this width, check it fits the largest contents, namely "nor | but does" */
}

.advanced_note {
  width: 100px;
  @include tablet {
    display: none;
  }
}

.advanced_csv_in {
  height: 17px;
  overflow: hidden;
  word-wrap: normal;
  white-space: nowrap;
}

tr {
  &[data-field=f\.ship_mmsi] .advanced_criteria input.number {
    width: 80px;
  }

  &[data-field=a\.ais_ship_point] {
    .advanced_criteria input.number {
      margin: 0 2px 4px 0;
    }

    .decimal {
      input.deg {
        width: 80px;
      }

      .fraction {
        display: none;
      }
    }
  }
}

.advanced_fields {
  a {
    display: block;
    float: left;
    padding: 0 8px 8px 4px;

    &.advanced_hidden {
      display: none;
    }
  }

  span {
    display: block;
    width: 150px;
    float: none;
    padding: 0;
    margin: 0 0 4px;
  }

  &.search_types {
    width: auto;

    ul li {
      float: none;

      a {
        width: 100%;
      }
    }

    > div > div {
      @include mobile {
        height: auto !important;
        padding: 0 !important;
      }
    }
  }

  &.search_polygon_types {
    width: 180px;
    max-width: 180px;
  }

  &.advanced_dropdown a:hover, .search_types_box a:hover {
    text-decoration: underline;
  }
}

.advanced_buttons {
  border-top: 2px solid $vv-dark-red;
  text-align: right;
  padding: 12px 0 20px;
  margin-bottom: 8px;

  &.search_group {
    border-top: none;
    margin-bottom: 0;
  }
}

.additional_search_container {
  border-top: 1px solid $vv-dark-red;
}

#vv_at_container {
  .advanced_search_block {
    div {
      ul.advanced_dropdowns {
        li {
          flex: initial;
        }
      }
    }
  }
}

.advanced_dropdowns {
  display: flex;
  flex: 1 1 auto;
  flex-wrap: wrap;
  clear: both;
  margin-top: 22px;

  @include sm-desktop-min {
    border-bottom: 1px solid $vv-dark-red;
  }

  > li {
    margin-right: 4px;
    max-width: 100%;
    position: relative;

    @include sm-tablet {
      flex: auto;
    }

    &:before {
      content: '';
      background-color: $vv-dark-red;
      position: absolute;
      width: 100%;
      height: 1px;
      padding-right: 4px;
      bottom: -1px;
      left: -2px;
      box-sizing: content-box;
    }

    &.flex-next-row-mobile, &.flex-next-row-tablet {
      display: none;
      flex-basis: 100%;
      height: 0;
      padding-bottom: 0;
    }

    &.flex-next-row-mobile {
      @include sm-tablet {
        display: block;
      }
    }

    &.flex-next-row-tablet {
      @include sm-tablet-only {
        display: block;
      }
    }

    &.flex-next-row-tablet:last-child {
      display: none;
    }

    &.flex-end {
      display: flex;
      margin-left: auto;

      @include tablet {
        margin-left: initial;
        flex: 1;
      }
    }

    &.flex-next-row-mobile, &.flex-next-row-tablet {
      &:before {
        height: 0;
      }

      &:after {
        content: initial;
      }
    }

    &.disabled {
      span.advanced_dropdown {
        background: url(../img/dropdown-inactive.png) right no-repeat !important;
        border-color: $dimmed-grey !important;
        color: $dimmed-grey !important;
      }

      &:hover span.advanced_dropdown {
        background: $dimmed-grey url(../img/dropdownactive.png) right no-repeat !important;
        color: $white !important;
      }
    }

    &.advanced_showing {
      span.advanced_dropdown {
        background: $vv-red url(../img/dropdownactive.png) right no-repeat;
        color: $white;
      }

      div.advanced_dropdown {
        display: block;
      }
    }

    &.dimmed span.advanced_dropdown {
      border-color: $dimmed-grey;
      color: $dimmed-grey;
      background: url(../img/loadingAnimation.gif) 90% no-repeat;
      background-size: 10px;
    }

    > span {
      &.advanced_dropdown {
        &.bbsols_only {
          border-color: $bb-sols-primary;
          color: $bb-sols-primary;
        }

        &.seasure_only {
          border-color: $vv-seasure-blue;
          color: $vv-seasure-blue;
        }
      }

      &:hover span.advanced_dropdown {
        &.bbsols_only, .bbsols_only {
          background-color: $bb-sols-primary;
          color: $white;
        }

        &.seasure_only, .seasure_only {
          background-color: $vv-seasure-blue;
          color: $white;
        }
      }

      @include mobile {
        border-radius: 0;
        border-bottom: $vv-red 1px solid;
      }
    }

    .advanced_fields {
      &.search_types {
        .search_types_box {
          width: 180px;
          height: 210px;
          padding-bottom: 20px;
          @include sm-tablet {
            max-width: 526px;
          }
        }
      }
    }
  }

  div:not(.new-menu-item-container).advanced_dropdown, div.advanced_fields {
    min-width: 184px;
  }

  div.advanced_dropdown.scrollable {
    max-height: 340px;
    overflow-y: scroll;
  }

  &.original_dropdown {
    @include desktop {
      width: 1184px;
    }
    @include tablet {
      max-width: 745px;
    }
    @include sm-tablet {
      max-width: 526px;
    }
  }

  .search_ship_types {
    @include tablet {
      max-width: 745px;
    }
    @include sm-tablet {
      max-width: 526px;
    }
    @include mobile {
      max-width: 250px;
    }
  }

  select {
    margin-bottom: 12px;
    display: block;
    float: none;
    @include mobile {
      max-width: 250px;
    }
  }

  h1 {
    margin: 4px 0;
    display: block;
    float: none;
  }

  a, span:not(.new-tag) {
    @include mobile-min {
      font-size: revert;
    }
  }

  a {
    float: none;
    padding: 3px 17px;
    margin: 0;

    &.advanced_remove_block {
      padding: 7px 5px 5px 4px;
    }
  }

  > li > div > a:hover {
    background-color: $dark-grey-yellow;
  }

  @include sm-tablet {
    height: auto;
  }

  @include mobile {
    border: 0;
  }
}

span.advanced_dropdown.top-level-dropdown, .new-menu-item-container {
  padding: 6.5px 20px 6px 8px;
  border-radius: 4px 4px 0 0;
  display: block;
  background: url(../img/dropdown.png) right no-repeat;
  font-weight: normal;
  cursor: pointer;
  color: $vv-dark-red;
  border: 1px solid $vv-dark-red;
  border-bottom: 0 none;
}

div:not(.new-menu-item-container).advanced_dropdown {
  position: absolute;
  display: none;
  background-color: $light-cream-yellow;
  border: $lighter-dark-grey 1px solid;
  border-top-color: $vv-red;
  z-index: 20;
  @include mobile {
    padding: 8px;
  }
}

.advanced_remove_block {
  float: right;
  margin: 20px 0 -20px;
  @include tablet {
    margin: 10px 0;
  }
  @include sm-tablet {
    margin: 5px;
    padding: 7px;
  }
  @include mobile {
    width: 85px;
    margin: 5px 15px 5px 5px;
    display: block;
  }
}

.advanced_search_block_spare {
  display: none;
}

a.more_links.expanded:after {
  content: "-";
  font-size: 1.5em;
}
