@import '../abstracts/colors';
@import '../abstracts/variables';

a {
  background-color: transparent;
  color: $vv-dark-red;
  cursor: pointer;
  text-decoration: none;
  &:hover, &:active {
    color: $vv-darkest-red;
  }
  &:focus {
    text-decoration: none;
    outline: thin dotted;
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px;
  }
  &.disabled {
    pointer-events: none;
    cursor: default;
    color: $dimmed-grey;
  }
}

button, .button,
input[type=submit], input[type=reset], input[type=button],
.ui-dialog-buttonset .ui-state-default,
.ui-dialog[aria-describedby="popup_container"] .ui-dialog-content button {
  color: $white;
  background: $vv-dark-red none;
  border: 1px solid $vv-dark-red;
  border-radius: 3px;
  &.button-rounded {
    border-radius: 20px;
  }
  padding: 2px 11px;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  overflow: visible;
  font-family: inherit;
  -webkit-appearance: none;
  &:hover, &:active {
    background-color: $white;
    color: $vv-dark-red;
  }
  &.inverted {
    border-color: $vv-dark-red;
    background-color: $white;
    color: $vv-dark-red;
    &:hover, &:active {
      background-color: $vv-dark-red;
      color: $white;
    }
    &.trans {
      border-color: $white;
      &:hover, &:active {
        border-color: $white;
        background-color: transparent;
        color: $white;
      }
    }
  }
  &.lg_input {
    padding-top: 11px;
    padding-bottom: 11px;
  }
  &.sm-input {
    font-size: $font-size-xs;
  }
  /* Dimmed Button */
  &.dimmed, &.dimmed:hover,
  &.disabled, &.disabled:hover,
  &[disabled="disabled"], [disabled="disabled"]:hover {
    background-color: $dimmed-grey;
    border-color: $dimmed-grey;
    color: $white;
    cursor: default;
  }
  &::-moz-focus-inner {
    padding: 0;
    border: 0;
  }
  &.document {
    text-transform: none;
  }
  &.transparent {
    background: transparent;
    border-color: $white;

    &:hover {
      background: $white;
      color: $vv-dark-red;
    }
  }
  &.button-white {
    background: $white;
    border-color: $white;
    color: $vv-dark-red;

    &:hover {
      background: $vv-dark-red;
      color: $white;
    }
  }
}
button, .button {
  display: inline-block;
  text-transform: lowercase;
  &:first-letter {
    text-transform: uppercase;
  }
  &.vv-uppercase {
    text-transform: uppercase;
  }
  &.initial {
    text-transform: none;
  }
}
.ms-options-wrap > button {
  text-transform: none;
}
/* Custom Buttons */
/* iGuides */
a.more_info, span.more_info {
  font-family: Georgia, "Times New Roman", serif;
  letter-spacing: 0.2em;
  font-weight: bold;
  font-style: italic;
  vertical-align: super;
  font-size: 9px;
}
i.super {
  vertical-align: super;
  font-size: 9px;
}
.gloss_btn {
  width: 30px;
  height: 30px;
  background: url('../img/close.png') no-repeat center;
  background-size: contain;
  margin-right: 10px;
  &.collapsed {
    background-image: url('../img/open.png');
  }
}
.arrow_btn {
  -webkit-transform: scaleY(-1);
  transform: scaleY(-1);
}

.button_padding {
  padding: 7px 12px;
}

.button_white {
  background: $white;
  color: $vv-dark-red;
}

.button_white:hover {
  background: $vv-dark-red;
  color: $white;
}
