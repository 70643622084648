@mixin flex($prefix: '') {
  @if ($prefix != '') {
    $prefix: #{$prefix}-
  }

  .#{$prefix}flex {
    display: flex;

    &-col {
      flex-direction: column;

      &-reverse {
        flex-direction: column-reverse;
      }
    }

    &-row {
      flex-direction: row;

      &-reverse {
        flex-direction: row-reverse;
      }
    }

    &-shrink {
      flex-shrink: 1;

      &-0 {
        flex-shrink: 0;
      }
    }

    &-basis-0 {
      flex-basis: 0;
    }

    &-wrap {
      flex-wrap: wrap;
    }

    &-no-wrap {
      flex-wrap: nowrap;
    }

    &-1 {
      flex: 1 1 0%;
    }

    &-auto {
      flex: 1 1 auto;
    }

    &-initial {
      flex: 0 1 auto;
    }

    &-none {
      flex: none;
    }
  }
}

@mixin justify($prefix: '') {
  @if ($prefix != '') {
    $prefix: #{$prefix}-
  }

  .#{$prefix}justify {
    &-between {
      justify-content: space-between;
    }

    &-start {
      justify-content: flex-start;
    }

    &-end {
      justify-content: flex-end;
    }

    &-evenly {
      justify-content: space-evenly;
    }

    &-center {
      justify-content: center;
    }

    &-normal {
      justify-content: normal;
    }
  }
}

@include flex
@include justify
@include mobile {
  @include flex('sm');
  @include justify('sm');
}

@include tablet {
  @include flex('md');
  @include justify('md');
}

@include sm-desktop {
  @include flex('lg');
  @include justify('lg');
}

@include desktop {
  @include flex('xl');
  @include justify('xl');
}
