@import '../abstracts/colors';
@import '../abstracts/mixins';

.login_nav_con {
  .login {
    z-index: 9999999999;
    height: auto;
    padding: 15px;
    position: absolute;
    display: none;
    top: 50px;
    left: inherit;
    right: 0 !important;
    background-color: $white;
    border: 1px solid $lighter-grey;
    width: 285px;
    border-radius: 4px;
    @include sm-tablet {
      top: 35px;
      left: 10px;
    }
  }
  /* Logged-In System Menu (Services / My VV) */
  /* System functions menu items */
  .login_nav {
    font-size: 0.9em;
    margin-top: 5px;
    ul li {
      display: inline-block;
      vertical-align: bottom;
      margin-left: 15px;
      z-index: 5;
      &.dropdown ul li {
        display: block;
        background-color: transparent;
        margin: 0;
      }
      &.black {
        background-color: $black;
      }
      a {
        display: block;
        padding: 5px 15px;
      }
    }
  }
}
#responsive_nav_con, .login_nav_con {
  ul li {
    &.lang-dropdown {
      > a {
        color: $white;
        padding: 5px 0;
        > img.flag {
          width: 20px;
          box-shadow: 1px 1px 6px rgba($black, 0.7);
        }
      }
    }
  }
}
