@import '../abstracts/colors';
@import '../abstracts/mixins';

/* Notifications */
.notification {
  background-color: $white;
  border: 2px solid $vv-darkest-mid-grey;
  font-size: 1.333em;
  font-weight: normal;
  margin: 20px auto;
  width: 75%;
  &.small {
    font-size: 1em;
  }
  &.success,
  &.fail,
  &.red,
  &_red {
    background-color: $white;
    border: 1px solid $vv-dark-red;
    color: $black;
    font-size: 1.333em;
    font-weight: normal;
    margin: 20px 0;
    padding: 20px 0;
    width: 99.5%;
    text-align: center;
  }
  &.warning {
    color: $warning-alert-dark-yellow;
    background-color: $warning-alert-light-yellow;
    border-color: $warning-alert-mid-yellow;
  }
  &.fail {
    color: $error-alert-dark-pink;
    background-color: $error-alert-light-pink;
    border-color: $error-alert-mid-pink;
  }
  &.success {
    color: $success-alert-dark-green;
    background-color: $success-alert-light-green;
    border-color: $success-alert-mid-green;
  }
  &.pl {
    padding: 0;
    margin: 0;
    border: 1px dotted $dark-grey-yellow;
    font-size: 1em;
    width: auto;
    text-align: center;
    p {
      padding: 5px;
      margin: 0;
    }
  }
  .head {
    color: $notification-dark-green;
    margin-bottom: 10px;
  }
}
@include mobile {
  .sample_graph .notification_red {
    width: 320px;
    margin: 0 auto;
    padding: 0;
  }
}
.notification-box {
  border: 2px solid $vv-darkest-mid-grey;
  padding: 0 20px;
  margin-bottom: 15px;
  @include mobile {
    margin: 0 5px;
  }
  p {
    color: $lighter-dark-grey;
  }
}

.vv_info_message {
  color: $info-alert-dark-blue;
  background-color: $info-alert-light-blue;
  border: 1px solid $info-alert-mid-blue;
  position: relative;
  padding: 12px 20px;
  margin: 16px 30px;
  border-radius: 4px;
}
