@import '../abstracts/colors';

/* Micro Map, Ship Direction and Report Details
 * Found on the valuation, company and portfolio pages
 */
.micro_map,
.micro_ship_photo {
  height: 38px;
  width: 72px;
  display: block;
  float: left;
  margin-right: 12px;
  cursor: pointer;
  position: relative;
}
.micro_map {
  background-image: url(../img/at/micromap.png);
  img {
    background-color: $white;
    position: absolute;
    height: 5px;
    width: 5px;
    margin-left: -2px;
    margin-top: -2px;
  }
  &_many img {
    height: 3px;
    width: 3px;
    margin-left: -1px;
    margin-top: -1px;
  }
  &_report {
    float: left;
    margin-top: -4px;
    > span {
      display: inline-block;
      margin-right: 12px;
    }
  }
}
.ship_direction {
  height: 41px;
  width: 41px;
  margin-right: 8px;
  background-repeat: no-repeat;
  vertical-align: top;
  float: left;
}
.ship-details-container {
  float: left;
  > span {
    display: inline-block;
    margin-right: 12px;
  }
}
