@import '../abstracts/mixins';

$grid-columns: (
  (class : '100', p : 50px, a : 100px),
  (class : '125', p : 75px, a : 125px),
  (class : '150', a : 150px),
  (class : '200', p : 100px, a : 200px),
  (class : '300', m: 300px),
  (class : '400', m: 400px),
);

@media print {
  @each $grid-column in $grid-columns {
    $grid-class: map-get($grid-column, class);
    @if (is-not-null(map-get($grid-column, p))) {
      .p#{$grid-class} {
        width: map-get($grid-column, p);
      }
    }
    @if (is-not-null(map-get($grid-column, a))) {
      .pa#{$grid-class} {
        width: map-get($grid-column, a);
      }
    }
    @if (is-not-null(map-get($grid-column, m))) {
      .pm#{$grid-class} {
        min-width: map-get($grid-column, m);
      }
    }
  }
}
