@import '../abstracts/colors';

.terms_container {
  border: dashed 1px $vv-darkest-mid-grey;
  padding: 15px;
  height: 135px;
  overflow: auto;
  box-sizing: border-box;

  .bbbump {
    font-size: 14px;
    font-weight: normal;
    line-height: 17px;
    margin-top: 0;

    strong {
      font-weight: normal;
    }
  }

  p {
    font-size: 14px;
  }
}
