@import '../abstracts/colors';
@import '../abstracts/mixins';

.to_top {
  color: $white;
  background: url("../img/up-r.png") no-repeat center top;
  background-size: 50px 50px;
  display: inline-block;
  padding-top: 60px;
  width: 120px;
  height: 100px;
  text-align: center;
}

#expiry_enquiry {
  width: 460px;
  min-width: 460px;
  max-width: 460px;
  height: 100px;
  max-height: 150px;
  min-height: 50px;
}
