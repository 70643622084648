@import '../abstracts/mixins';

/*
* Creates styles from w10 to w175, 5px increments
**/
@for $i from 2 through ceil(175/5) {
  $width: $i * 5;

  .w#{$width} {
    @include width(#{$width}px, #{$width}px, #{$width}px);
  }
}

/*
* Creates styles from w200 to w350, 10px increments
**/
@for $i from 20 through ceil(350/10) {
  $width: $i * 10;

  .w#{$width} {
    @include width(#{$width}px, #{$width}px, #{$width}px);
  }
}

/*
* Creates styles from w400 to w600, 25px increments
**/
@for $i from 16 through ceil(600/25) {
  $width: $i * 25;

  .w#{$width} {
    @include width(#{$width}px, #{$width}px, #{$width}px);
  }
}

@for $i from 2 through (200 / 5) {
  $width: $i * 5;

  .min-width-#{$width} {
    min-width: #{$width}px;
  }
}

@for $i from 2 through (200 / 5) {
  $width: $i * 5;

  .max-width-#{$width} {
    max-width: #{$width}px;
  }

  .max-width-#{$width}p {
    max-width: percentage($width / 100);
  }
}

@include tablet {
  .w30 {
    @include width(25px, 25px, 30px);
  }

  .w35 {
    @include width(35px, 30px, 35px);
  }

  .w40px,
  .w45 {
    @include width(40px, 30px, 40px);
  }

  .w50 {
    @include width(50px 40px, 50px);
  }

  .w55,
  .w60 {
    @include width(45px, 40px, 50px);
  }

  .w65 {
    @include width(45px, 45px, 50px);
  }

  .w75,
  .w80,
  .w85 {
    @include width(70px, 50px 70px);
  }

  .w100 {
    @include width(80px, 100px, 100px);
  }

  .w125 {
    @include width(100px, 100px, 125px);
  }

  .w130 {
    @include width(120px, 0, 130px);
  }

  .w135 {
    @include width(120px, 0, 135px);
  }

  .w150 {
    @include width(120px, 100px, 150px);
  }

  .w165 {
    @include width(150px 100px, 165px);
  }

  .w175 {
    @include width(150px 100px, 175px);
  }

  .w200 {
    @include width(100px, 100px, 200px);
  }

  .w220 {
    @include width(auto, 100px, 220px);
  }

  .w250 {
    @include width(auto, 100px, 250px);
  }

  .w280 {
    @include width(auto, 100px, 280px);
  }

  .w300 {
    @include width(auto, 150px 300px);
  }

  .w350 {
    @include width(auto, 150px 350px);
  }

  .w400 {
    @include width(auto, 200px, 400px);
  }

  .w425 {
    @include width(auto, 200px, 425px);
  }

  .w450 {
    @include width(auto, 200px, 560px);
  }

  .w475 {
    @include width(auto, 250px 475px);
  }

  .w500 {
    @include width(auto, 250px 500px);
  }

  .tablet_100 {
    width: 90px;
  }

  .tablet_125 {
    width: 115px;
  }

  .tablet_150 {
    width: 150px;
  }

  .tablet_w220 {
    @include width(220px, 100px, 220px);
  }
}

@include sm-tablet {
  .p50 {
    width: 50px;
  }

  .p100 {
    width: 100px;
  }

  .p125 {
    width: 125px;
  }

  .p200 {
    width: 200px;
  }

  .sm_tablet_only_w50 {
    width: 50px;
    min-width: 50px;
    max-width: 50px;
  }
}

@include mobile {

  .w20,
  .w25,
  .w30 {
    @include width(30px, null, 30px);
  }

  .w35 {
    @include width(35px, null, 35px);
  }

  .w40px,
  .w45 {
    @include width(40px, null, 40px);
  }

  .w50,
  .w55 {
    @include width(50px null, 50px);
  }

  .w60,
  .w65 {
    @include width(50px null, 50px);
  }

  .w75,
  .w80,
  .w85 {
    @include width(70px, null, 70px);
  }

  /*
  * Creates styles for mobile from w100 to w175, 5px increments
  **/
  @for $i from 20 through ceil(175/5) {
    $width: $i * 5;

    .w#{$width} {
      @include width(#{$width}px, null, #{$width}px);
    }
  }

  .w200 {
    width: 200px;
    max-width: 200px;
    min-width: 100px;
  }

  .w220 {
    width: 220px;
    max-width: 220px;
    min-width: 100px;
  }

  .w250 {
    width: 250px;
    max-width: 250px;
    min-width: 150px;
  }

  .w280 {
    width: 280px;
    max-width: 280px;
    min-width: 150px;
  }

  /*
  * Creates styles for mobile from w300 to w500, 10px increments
  **/
  @for $i from 30 through ceil(500/10) {
    $width: $i * 5;

    .w#{$width} {
      @include width(#{$width}px, null, #{$width}px);
    }
  }

  .p75 {
    width: 75px;
  }

  .mobile_only_w50 {
    width: 50px;
    min-width: 50px;
    max-width: 50px;
  }

  .mobile_only_w60 {
    width: 60px;
    min-width: 60px;
    max-width: 60px;
  }
}

/**
 * The following creates a new width range of properties (matching the height range) which explicitily
 * create seperate classes for height, min-height and max-height. The current set of w10 width range
 * sets the height as well as min and max values, clouding the intention of setting a width.
 *
 * Each loop will create a set of classes (e.g. vv-w-10 min-w-10 max-w-10)
 */

// Creates styles from w/h-10 to w/h-175, 5px increments
@for $i from 0 through ceil(175 / 5) {
  @include dimensions('width', $i * 5);

  @include mobile {
    @include dimensions('width', $i * 5, 'sm');
  }

  @include tablet {
    @include dimensions('width', $i * 5, 'md');
  }

  @include desktop {
    @include dimensions('width', $i * 5, 'lg');
  }
}

// Creates styles from w/h-200 to w/h-350, 10px increments
@for $i from 20 through ceil(350 / 10) {
  @include dimensions('width', $i * 10);
}

// Creates styles from w/h-400 to w/h-550, 25px increments
@for $i from 16 through ceil(750 / 25) {
  @include dimensions('width', $i * 25);
}


@include mobile {
  .vv-sm-max-w-100vw{
    max-width: 100vw;
  }
}

.vv-w-80vw {
  width: 80vw;
}

/**
 * Widths
 * This will create all width options for each screen size.
 * E.g: Full width: .w-full | .xl-w-full | .lg-w-full | .md-w-full | .sm-w-full | .xs-w-full | .xxs-w-full
 * Usage Document: http://confluence.vvdev.co.uk/display/DEV/Sass+layout#SassLayoutWidthsClasses
 */

$widths: (auto, half, full, inherit, initial, fit-content);
@each $width in $widths {
  $width-value: $width;

  @if($width == full) {
    $width-value: '100%';
  }

  @if($width == half) {
    $width-value: '50%';
  }

  .vv-w-#{$width} {
    width: #{$width-value};
  }

  @include desktop() {
    .vv-xl-w-#{$width} {
      width: #{$width-value};
    }
  }

  @include sm-desktop() {
    .vv-lg-w-#{$width} {
      width: #{$width-value};
    }
  }

  @include tablet() {
    .vv-md-w-#{$width} {
      width: #{$width-value};
    }
  }

  @include sm-tablet() {
    .vv-sm-w-#{$width} {
      width: #{$width-value};
    }
  }

  @include mobile() {
    .vv-xs-w-#{$width} {
      width: #{$width-value};
    }
  }

  @include sm-mobile {
    .vv-xxs-w-#{$width} {
      width: #{$width-value};
    }
  }
}

// This class is used to counteract the everythingbarthefooter max:width:100%;
// When that has been sorted we can remove the !important and move this into
// a more helpful set of responsive classes.
.vv-max-w-initial {
  max-width: initial !important;
}

/** @deprecated @see vv-w-auto */
.vv-wa {
  width: auto;
}

.vv-mw10 {
  max-width: 10px;
}

.vv-max-w-full {
  max-width: 100%;
}

.vv-w-750 {
  width: 750px;
}

.vv-wp-95 {
  width: 95%;
}

