@import '../abstracts/colors';
@import '../abstracts/mixins';

/* Background images */
.main_bg {
  background-image: url("../img/home-bg-2017.jpg");
  background-repeat: repeat-x;
  background-position: center -170px;
  padding-bottom: 0;

  @include sm-desktop {
    background-position: center -150px;
  }

  @include tablet {
    background-position: 4% 100%;
    background-size: auto 150%;
  }

  @include mobile {
    background-position: 10% 100%;
    background-size: auto 150%;
  }

  @media (max-width: 380px) {
    h1 {
      margin-top: 0;
    }
  }
}

.ship_bg_img {
  background-image: url("../img/red_ship_bg.jpg");
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: auto 100%;
}

.register_bg {
  background-image: url("../img/registration-bg.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% auto;
  background-attachment: fixed;
  min-height: 280px;

  @include sm-tablet {
    background-size: auto 100%;
  }
}

.alt_bg_img {
  background-image: url("../img/alt_clients_bg.jpg");
  background-repeat: repeat-x;
  background-position: 0 -30%;

  @include sm-tablet {
    background-size: auto 120%;
  }
}

.subscriptions_bg_img {
  background-image: url("../img/subscriptions_bg.png");
  background-repeat: no-repeat;
  background-position: right bottom;
}

.position_list_bg {
  background-image: url("../img/position-list-bg.png");
  padding-bottom: 0;
  background-position-x: center;
  background-size: auto;
  background-position: center;
  position: relative;
  height: 100%;
  width: 100%;

  @media (min-width: 1920px) {
    background-size: 100% auto;
    background-position: center center;
  }
}

.superyachts_bg {
  background-image: url("../img/services/superyacht-desktop-header.jpg");
  padding-bottom: 0;
  background-position-x: center;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  height: 100%;
  width: 100%;
  @include tablet {
    background-image: url(../img/services/superyacht-tablet-header.jpg);
  }
  @include mobile {
    background-image: url(../img/services/superyacht-mobile-header.jpg);
  }
}

.cruise_bg {
  background-image: url("../img/cruise-landing/cruise-desktop-header.png");
  padding-bottom: 0;
  background-position-x: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 65%;
  position: relative;
  height: 100%;
  width: 100%;
  @include tablet {
    background-image: url("../img/cruise-landing/cruise-tablet-header.png");
    background-position: 65% bottom;
  }
  @include mobile {
    background-image: url("../img/cruise-landing/cruise-mobile-header.png");
    background-position: 65% bottom;
  }
}

.bg_gradient_red {
  background: linear-gradient(to right, $gradient-red-start 0%, $gradient-red-end 100%);
}

/* [IE11] */
.bg-gradient-red-to-bottom {
  background: linear-gradient(
    to bottom,
    $gradient-red-start 0%,
    $gradient-red-end 100%
  );
}

.grad_bg {
  background-color: $white;
  background-image: url("../img/bg_grad.png");
  background-repeat: repeat-x;
}

/* Background colours */
.black_bg {
  background-color: $black;
}

.black_con {
  background-color: $darkest-grey;
}

.white_bg {
  background-color: $white;
}

.lightestgrey_bg {
  background-color: $vv-light-grey;
}

.lgrey_bg {
  background-color: $lighter-grey;
}

.bg-lighter-mid-grey {
  background-color: $lighter-mid-grey;
}

.lightgrey_bg {
  background-color: $lightest-mid-grey;
}

.grey_bg {
  background-color: $lighter-grey;
}

.midgrey_bg {
  background-color: $vv-darkest-mid-grey;
}

.dgrey_bg {
  background-color: $darker-grey;
}

.red_bg {
  background-color: $vv-red;
  color: $white;
}

.dyellow_bg {
  background-color: $vv-dark-yellow;
}

.lyellow_bg {
  background-color: $vv-light-yellow;
}

.alternate_bg {
  background-color: $vv-dark-teal;
  color: $white;
}

.dteal_bg {
  background-color: $vv-dark-teal;
}

.lteal_bg {
  background-color: $vv-light-teal;
}

.dgrey_dialog_titlebar .ui-dialog-titlebar {
  background-color: $darker-grey;
}

.map_bg {
  background-color: $lightest-mid-grey;
}

.bg_center {
  background-position: center;
}

.bg_right_tiny_pr {
  background-position: right 5px top 5px;
}

.bg_nr {
  background-repeat: no-repeat;
}

.bg-mint-green {
  background-color: $mint-green;
}

.bg-transparent {
  background-color: transparent;
}

// IE11 background transparency fix
.bg-white-opacity-25,
.bg-white-opacity-25-hover:hover,
.hover\:bg-white-opacity-25:hover {
  background-color: rgba(255, 255, 255, 0.25);
  color: $white;
}

.bg-grey-opacity-25,
.bg-grey-opacity-25-hover:hover,
.hover\:bg-grey-opacity-25:hover {
  background-color: rgba(229, 229, 229, 0.25); // $lighter-mid-grey
}

.bg-grey-opacity-90,
.bg-grey-opacity-90-hover:hover {
  background-color: rgba(229, 229, 229, 0.9); // $lighter-mid-grey
  color: $darker-grey;
}
