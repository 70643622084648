@import "../layout/print-grid";
@import "../layout/print-widths";
@import '../abstracts/colors';

@media print {
  .tabbing a {
    border-radius: 0;
    float: none !important;
    padding: 5px;
    display: inline-block;

    &.on_right {
      margin-top: 10px;
    }
  }
  .chart_wrapper {
    max-width: 600px;
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .print_only {
    display: inherit;
    display: initial;
  }
  .logo {
    svg {
      margin-left: auto;
      margin-right: auto;
    }
  }
  #logo {
    color: $darkest-grey;
  }
  .tablet_scroll, .mobile_scroll, .sm_tablet_scroll {
    overflow-x: visible;
    overflow-y: visible;
    height: auto !important;
    -webkit-overflow-scrolling: touch;
  }
  .sm_tablet_hide, .phone_hide, .debug_database_queries {
    display: none !important;
  }
  .navbar,
  .main_nav,
  .ticker_con,
  #menu_subnav,
  .top_links,
  .chart_aside,
  #zopim_chat,
  .footer_top .one_quarter,
  .footer_top .one_half,
  .seasure_only,
  #val_date_historical_link,
  .footer.containers {
    display: none !important;
  }
}
