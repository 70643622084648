.please-wait {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(100, 100, 100, 0.3);
  display: none;

  .text-muted {
    position: absolute;
    top: 45%;
    width: 100%;
    text-align: center;
  }
}

.loading-animation-container {
  width: 99%;
  height: 400px;
  border: 1px solid #CCCCCC;
}

.loading-animation {
  display: block;
  margin: 15% auto;

  &.inline {
    display: inline;
  }

  &.xlarge {
    width: 100px;
    height: 100px;
  }

  &.large {
    width: 70px;
    height: 70px;
  }

  &.medium {
    width: 50px;
    height: 50px;
  }

  &.small {
    width: 20px;
    height: 20px;
  }

  &.tiny {
    width: 10px;
    height: 10px;
  }
}
