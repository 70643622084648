@import '../../abstracts/colors';
@import '../../abstracts/mixins';

// jQuery UI Dialog
.ui-dialog {
  max-width: 95%;
  box-shadow: 1px 1px 4px 0 rgba(0,0,0,0.5);
  &:focus {
    outline: none;
  }
  .ui-dialog-titlebar-close {
    span {
      margin: 0;
    }
    span.ui-button-text {
      display: none;
    }
    .ui-icon-closethick {
      background-position: -95px -125px;
    }
    .ui-icon-closethick:hover {
      opacity: 0.75;
    }
  }
  .ui-dialog-title {
    margin: 2px;
    cursor: default;
    user-select: none;
    min-height: 23px;
    padding-right: 20px;
  }
  .ui-dialog-buttonpane {
    padding: 0 1em 1em;
    margin-top: 0.5em;
  }
  &[aria-describedby="popup_container"] {
    .ui-dialog-buttonset {
      width: 100%;
      .popup_button_third {
        width: calc(33.33% - 4px);
      }
    }
    .ui-dialog-buttonpane button {
      margin: 2px;
      box-sizing: border-box;
      height: 50px;
      user-select: none;
    }
  }
  @include mobile {
    max-width: 320px;
  }
}
.ui-dialog-content {
  font-size: 0.875rem;
}
.ui-dialog img,
.ui-dialog-content img {
  max-width: 100%;
}
.ui-widget-overlay {
  position: fixed;
}
.ui-dialog,
.ui-dialog-titlebar {
  border: none;
}
.ui-widget-header .ui-state-hover,
.ui-widget-header .ui-state-focus,
.ui-dialog[aria-describedby="popup_container"] .ui-dialog-content button:hover {
  color: $vv-red !important;
  background-color: $white;
  outline: none;
}
.ui-dialog-buttonset,
.ui-dialog-content ,
.ui-dialog-buttonset {
  .ui-state-hover,
  .ui-state-focus {
    outline: none;
  }
}
.ui-state-hover, .ui-state-focus {
  outline: 5px auto -webkit-focus-ring-color;
}
