@import '../abstracts/mixins';

.h-auto {
  height: auto;
}

.h-inherit {
  height: inherit;
}

.h-full {
  height: 100%;
}

.h-fit-content {
  height: fit-content;
}

.h418 {
  min-height: 418px;
}

.h440 {
  min-height: 440px;
}

.h510 {
  min-height: 510px;
}

.h80p {
  height: 80%;
}

// Creates styles from h-10 to h-175, 5px increments
@for $i from 0 through ceil(175 / 5) {
  @include dimensions('height', $i * 5);

  @include mobile {
    .sm-h-auto {
      height: auto;
    }

    .sm-h-fit-content {
      height: fit-content;
    }

    @include dimensions('height', $i * 5, 'sm');
  }

  @include tablet {
    .md-h-auto {
      height: auto;
    }

    .md-h-fit-content {
      height: fit-content;
    }

    @include dimensions('height', $i * 5, 'md');
  }

  @include sm-desktop-min {
    .lg-h-auto {
      height: auto;
    }

    .lg-h-fit-content {
      height: fit-content;
    }

    @include dimensions('height', $i * 5, 'lg');
  }

  @include desktop {
    .xl-h-auto {
      height: auto;
    }

    .xl-h-fit-content {
      height: fit-content;
    }

    @include dimensions('height', $i * 5, 'xl');
  }
}

// Creates styles from h-200 to h-350, 10px increments
@for $i from 20 through ceil(350 / 10) {
  @include dimensions('height', $i * 10);
}

// Creates styles from h-400 to h-550, 25px increments
@for $i from 16 through ceil(550 / 25) {
  @include dimensions('height', $i * 25);
}

.h20 {
  min-height: 20px;
}

.h40 {
  min-height: 40px;
}

.h85 {
  min-height: 85px;
}

.h195 {
  min-height: 195px;
}

.h225 {
  min-height: 225px;
}

.h265 {
  min-height: 265px;
}

.h275 {
  min-height: 275px;
}

.h300 {
  min-height: 300px;
}

.h350 {
  min-height: 350px;
}

.h375 {
  min-height: 375px;
}

.h400 {
  min-height: 400px;
}

.h425 {
  min-height: 425px;
}

.h450 {
  min-height: 450px;
}

.h500 {
  min-height: 500px;
}

.h550 {
  min-height: 550px;
}

.h600 {
  min-height: 600px;
}

.h675 {
  min-height: 675px;
}

.h780 {
  min-height: 780px;
  height: 780px;
}

@media screen and (max-width: 1000px) {

  .h225 {
    min-height: 225px;
  }

  .h265 {
    min-height: 265px;
  }

  .h275 {
    min-height: 275px;
  }

  .h300 {
    min-height: 300px;
  }

  .h350 {
    min-height: 350px;
  }

  .h375 {
    min-height: 375px;
  }

  .h400 {
    min-height: 400px;
  }

  .h425 {
    min-height: 425px;
  }

  .h450 {
    min-height: 450px;
  }

  .h500 {
    min-height: 500px;
  }

  .h600 {
    min-height: 600px;
  }

  .h675 {
    min-height: 675px;
  }

  .md-h-800 {
    height: 800px;
  }

  .tablet_125 {
    width: 115px;
  }

  .tablet_150 {
    width: 150px;
  }
}

@media screen and (max-width: 560px) {

  .h550,
  .h500,
  .h475,
  .h450,
  .h425,
  .h400,
  .h375,
  .h350,
  .h300,
  .h225,
  .h265,
  .h275,
  .h280,
  .h250,
  .h225 {
    min-height: 0;
  }

  .mobile_h500 {
    min-height: 500px;
  }

  .tablet_hauto {
    height: auto;
    min-height: 0;
  }
}

.max-h-460 {
  max-height: 460px;
}

@include mobile {
  .sm-80vh {
    height: 80vh;
  }
}

@include sm-tablet-min {
  .md-60vh {
    height: 60vh;
  }

  .md-80vh {
    height: 80vh;
  }
}

@include sm-desktop-min {
  .lg-vh-auto {
    height: auto;
  }
}
