// ==========================================================================
// override
// ==========================================================================

// ==========================================================================
// because sometimes hacks have to happen.
//
// be very specific about what each piece of code is doing, and
// how to better fix it later
// ==========================================================================

// Try:  $ git blame _override.scss

// Rules:
// ---------------
//   1. If it’s a hack, it goes in _override.scss.
//   2. Document all hacks fully:
//   3. What part of the codebase does it relate to?
//   4. Why was this needed?
//   5. How does this fix it?
//   6. How might you fix it properly, given more time?
//   7. Do not blame the developer; if they explained why they had to do it then their reasons are probably (hopefully) valid.
//   8. Try and clean _shame.scss up when you have some down time.

/* Source Select */
.functions form select {
  padding: 2px 0;
  margin: 0;
  width: 150px;
  border: none;
}

.dropdown-menu .functions {
  margin: 0;
  float: none;
}

@include mobile {
  .functions {
    margin-top: 5px;
  }
}
// Last remaining classes from flat.css
.page_sidebar p, .page_main_content p {
  margin-bottom: 0;
}

.embedded {
  height: 200px;
  width: 500px;
  overflow: auto;
  margin-bottom: 16px;
}

.zopim {
  overflow: visible !important;
}

#zopim_chat {
  position: fixed;
  bottom: -5px;
  right: 10px;
  z-index: 999;
}

.e_guide_col {
  padding-right: 10px !important;
  position: relative;
}

.e_guide_col .e_guide {
  position: absolute;
  top: 0;
  right: 0;
}

/* Generic Tabbing Style & Links above tabs on many pages. */
.back {
  padding: 0 0 10px 0;
}

.notabutton {
  background-color: transparent;
  border: none;
  color: #CC0000;
}

.notalink,
.notalink:active,
.notalink:hover {
  color: #000000 !important;
  cursor: default !important;
  text-decoration: none !important;
}

/* Pagination */

.page_active {
  background-color: #CC0000 !important;
  border-color: #CC0000 !important;
  color: #FFFFFF !important;
}

@media screen and (max-width: 560px) {
  .linkrow a {
    display: inline-block;
  }
}

.ship_type {
  height: 16px;
  background: url(../img/shipshapes.gif) no-repeat;
}

.market-loading {
  margin-left: 40%;
  margin-right: 40%;
  width: 20%;
  height: 20%;
}

.plain_aside p + p {
  padding: 5px 0;
  margin: 5px 0;
}

@media screen and (max-width: 560px) {
  #logout_link {
    overflow: hidden;
  }

  #responsive_nav_trigger {
    float: none;
  }
}

#far_avg_spd {
  margin-top: -4px;
}

#sb_logo {
  margin-right: 0 !important;
}

/* We have to use important here to override the spectrum styles - will be fixed with SASS */
.sp-replacer {
  border: 1px solid #CCC;
  background: #FFFFFF !important;
  padding: 0 !important;
}

.sp-preview {
  margin: 0 !important;
  border: 0 !important;
  min-height: 23px;
}
