@import '../abstracts/colors';
@import '../abstracts/mixins';

/* Valuation Details Panel including Valuation Amounts
 * Found on the valuation, company and portfolio pages
 */
.valuation_details {
  float: left;
  width: 100%;
  margin: 5px 0;
  .valuation_amount {
    float: left;
    font-weight: normal;
    min-height: 110px;
    @include mobile {
      + .valuation_amount {
        margin-left: 0;
      }
    }
    sup {
      font-size: 1.5rem;
      line-height: 1em;
    }
    .amount {
      font-size: 2.5rem;
    }
    .sub_values {
      font-size: 2.1875rem;
    }
    .dec {
      font-size: 1.5rem;
      a {
        font-size: 1rem;
      }
    }
    p {
      margin: 0;
      padding: 0;
      &.subamount {
        margin-bottom: 10px;
      }
    }
  }
  &.company .valuation_amount {
    min-height: inherit;
  }
}

#everythingbarthefooter .valuation_amount p a.button {
  background-color: transparent;
  color: $vv-dark-red;
  margin-top: 8px;
  margin-bottom: 8px;
  font-size: 12px;
  line-height: 2em;
  display: block;
  max-width: 100px;
  &:hover {
    background-color: $vv-dark-red;
    color: $white;
  }
}
