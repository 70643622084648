.scale_fx {
  transition: all .2s ease-in-out;
}

.scale_fx:hover {
  transform: scale(1.15);
}

.rotateimg180 {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}

.fade-in {
  animation-name: fadeIn;
  animation-duration: 500ms;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.fade-in-up {
  animation-name: fadeInUp;
  animation-duration: 500ms;
}

@keyframes fadeInUp {
  0% {
    transform: translateY(40px);
    opacity: 0;
  }

  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}

.fade-out-down {
  animation-name: fadeOutDown;
  animation-duration: 500ms;
}

@keyframes fadeOutUp {
  0% {
    transform: translateY(0);
    opacity: 1;
  }

  100% {
    transform: translateY(40px);
    opacity: 0;
  }
}

.fade-out {
  animation-name: fadeOut;
  animation-duration: 500ms;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.hover-icon-right {
  svg {
    transition: ease-in-out;
    transition-property: all;
    transition-duration: 500ms;
  }

  &:hover {
    svg {
      transform: translateX(5px);
    }
  }
}

.hover-icon-appear-left {
  svg.animate, span {
    transition: ease-in-out;
    transition-property: all;
    transition-duration: 200ms;
  }

  svg.animate {
    opacity: 0;
  }

  &:hover {
    svg.animate {
      opacity: 1;
    }

    span {
      transform: translateX(10px);
    }
  }
}

.hover-icon-appear-right {
  svg, span {
    transition: ease-in-out;
    transition-property: all;
    transition-duration: 250ms;
  }

  svg.animate {
    opacity: 0;
  }

  &:hover {
    svg.animate {
      opacity: 1;
      transform: translateX(20px);
    }

    span {
      transform: translateX(7px);
    }
  }
}

.card-hover-transition {
  transition: ease-in-out;
  transition-property: all;
  transition-duration: 500ms;

  &:hover {
    transform: scale(1.02) translateY(-3px);
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
  }
}
