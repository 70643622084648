@import '../abstracts/colors';

.vvtooltip {
  position: relative;
  display: inline-block;
  color: $vv-dark-red;
  .vvtooltiptext {
    visibility: hidden;
    width: 180px;
    background-color: $vv-dark-red;
    color: $white;
    text-align: center;
    border-radius: 6px;
    padding: 2px 0;
    position: absolute;
    font-size: 12px;
    z-index: 1;
    top: 150%;
    left: 50%;
    margin-left: -60px;
    &:after {
      content: " ";
      position: absolute;
      bottom: 100%; /* At the top of the tooltip */
      left: 50%;
      margin-left: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: transparent transparent $vv-dark-red transparent;
    }
    &.seasure_bg {
      background-color: $vv-seasure-blue;
      &:after {
        border-color: transparent transparent $vv-seasure-blue transparent;
      }
    }
  }
  &:hover .vvtooltiptext {
    visibility: visible;
  }
}
.destination_val_tooltip {
  display: inline-block;
  border-bottom: 1px dotted transparent;
  cursor: pointer;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  color: $vv-dark-red;
  &.seasure {
    color: $vv-seasure-blue;
  }
}

.tooltip {
  background: $vv-dark-grey;
  border-radius: 3px;
  position: absolute;
  color: $white;
  width: auto;
  height: 35px;
  padding: 7px 15px;
  text-align: center;
  font-size: 14px;

  &.bottom {
    bottom: 0;
    left: calc(50% - 75px);

    &:before{
      content: ' ';
      width: 10px;
      height: 10px;
      transform: rotate(45deg);
      top: -5px;
      left: calc(50% - 5px);
      position: absolute;
      background: $vv-dark-grey;
    }
  }
}
