@import '../abstracts/colors';
@import '../abstracts/mixins';

.search_con {
  background-color: $lightest-mid-grey;
  border-bottom: 1px solid $vv-darkest-mid-grey;
  width: 100%;
  position: absolute;
  margin: -2px auto 0;
  padding: 20px 0;
  display: none;
  z-index: 4;

  table.tableHeader tbody {
    tr {
      background-color: $lightest-mid-grey;
    }

    td {
      border-bottom-color: $lighter-mid-grey;

      &.sorted {
        border-bottom-color: $mid-grey;
        background-color:  $lightest-mid-grey;
      }
    }

    th.sorted {
      background-color: $lighter-mid-grey;
    }
  }
}

.search_div {
  background-color: $lightest-mid-grey;
  margin: 0 auto;
  color: $black;

  div {
    @include tablet {
      margin: 0 auto;
    }
  }
}

#quick_search_con {
  max-width: 100% !important;
  position: relative;

  #quick_search {
    position: relative;
    margin: 0;
    height: 40px;
    padding: 12px 8px 12px 34px;
    border: 0;
    width: 380px;
    background-color: $white;
    max-width: 70%;
    font-weight: 500;
    @include sm-tablet {
      width: 100%;
      max-width: 100%;
    }
    @include mobile {
      padding-left: 20px;
    }
  }

  form {
    position: relative;
    margin: 0;
  }

  #search_close {
    padding-left: 10px !important;

    .search_bar_close,
    .search_bar_close:hover,
    .search_bar_close:active {
      display: inline-block;
      padding: 11px 2px 0 2px;
      @include sm-tablet {
        padding: 0;
      }
    }

    @include sm-tablet {
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  &:before {
    /* Spyglass */
    content: " ";
    width: 25px;
    height: 25px;
    left: 5px;
    top: 10px;
    position: absolute;
    background: url("../img/search-r.png") no-repeat center;
    background-size: 100%;
    z-index: 1;
    @include mobile {
      left: -9999px;
    }
  }
}

#quick_search {
  color: $lighter-dark-grey;
  border-left: 2px solid $vv-red !important;
  border-right: 2px solid $vv-red !important;

  &:focus {
    border: 0;
    outline: 0;
    border-left: 2px solid $vv-dark-teal !important;
    border-right: 2px solid $vv-dark-teal !important;
  }
}
