@import "icons/variables";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?gr6wvw##{$icomoon-font-family}') format('svg'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?gr6wvw') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?gr6wvw') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="vv-icon-"], [class*=" vv-icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.vv-icon-check {
  &:before {
    content: $vv-icon-check;
  }
}
.vv-icon-close, .vv-icon-remove, .vv-icon-times {
  &:before {
    content: $vv-icon-close;
  }
}

.icon_links img {
  vertical-align: bottom;
}
