$grid-columns: (
  (class : 'one_fourteenth', percentage : 7.13%),
  (class : 'one_twelfth', percentage : 8.3%),
  (class : 'one_tenth', percentage : 10%),
  (class : 'one_eighth', percentage : 12.5%),
  (class : 'one_seventh', percentage : 14.28%),
  (class : 'one_sixth', percentage : 16.5%),
  (class : 'one_fifth', percentage : 20%),
  (class : 'one_quarter', percentage : 25%),
  (class : 'two_sevenths', percentage : 28.7%),
  (class : 'three_tenths', percentage : 30%),
  (class : 'one_third', percentage : 33.2%),
  (class : 'two_fifths', percentage : 40%),
  (class : 'one_half', percentage : 50%),
  (class : 'three_fifths', percentage : 60%),
  (class : 'two_thirds', percentage : 66.5%),
  (class : 'seven_tenths', percentage : 70%),
  (class : 'three_quarters', percentage : 75%),
  (class : 'four_fifths', percentage : 80%),
  (class : 'five_sixths', percentage : 83%),
  (class : 'nine_tenths', percentage : 90%),
  (class : 'full_width', percentage : 100%),
); // DO NOT REMOVE ITEMS

@media print {
  @each $grid-column in $grid-columns {
    $grid-class: map-get($grid-column, class);
    .print_#{$grid-class} {
      width: map-get($grid-column, percentage);
      &_push {
        margin-left: map-get($grid-column, percentage);
      }
    }
  }
  .print_no_cp {
    padding: 0 !important;
  }
  .print_updp {
    padding: 10px 0 !important;
  }
  .sm_tablet_full_width {
    width: 100%;
  }
}